/*This editor was created using Froala editor. 
 * Check https://www.froala.com/wysiwyg-editor/docs/ 
 * for methods, plugins, events, options and more to extend functionality*/

/*
 * We use a custom readonly implementation instead of the one built into Froala so
 * links in the editor can be clicked in readonly mode. If you try to switch to
 * Froala's built-in readonly mode, make sure JIRA item SML-2861 does not regress.
 */

(function () {
	angular.module("smartertools")
		.directive("stHtmlEditor", function ($rootScope, $compile, $timeout, $filter, $q, $log, $state, $translate, $http) {
		return {
			restrict: "E",
			scope: {
				saveDraft: "=",
				htmlContent: "=?",
				placeholder: "@",
				height: "@",
				minHeight: "@",
				maxHeight: "@",
				attachGuid: "@",
				afContext: "@",
				afSourceType: "@",
				afSourceId1: "@",
				afSourceId2: "@",
				afDomainOverride: "@",
				customDropdowns: "=",
				enterAction: "=", //'p', 'div', or 'br'
				tabSpaces: "=", //number of spaces to insert when tab is pressed. false will disable tab in editor so tab can be used for field navigation.
				stDisabled: "=",
				stFirstChange: "&",
				htmlTrustFunction: "=",	// Can be dangerous. Use this sparingly, and only on content that has already been sanitized.
				editorScope: "=?",
				editorScopeAux: "=?",
				enableImageUpload: "=?",
				onImageInserted: "=",
				onImageRemoved: "=?",
				onInitialized: "=",
				groupButtons: "=",
				showImageButton: "=?",
				enableBlur: "@"
			},
			link: function (scope, element, attrs) {
				scope.id = $(element)[0].id;
				scope.enableImageUpload = angular.isDefined(scope.enableImageUpload) ? scope.enableImageUpload : true;
				scope.showImageButton = angular.isDefined(scope.showImageButton) ? scope.showImageButton : true;
				scope.getHtml = null;
				scope.setHtml = null;
				scope.insertHtml = null;
				scope.insertLink = null;

				var carteac = $(element).attr('carteac');
				var showSignatureButton = $(element).attr('showSignatureButton') != undefined;
				scope.authStorage = null;
				if ($(element).injector()) {
					if ($(element).injector().has('authStorage')) {
						scope.authStorage = $(element).injector().get('authStorage');
					}
					else {
						scope.authStorage = {
							getToken: function () { return "0"; }
						};
					}
				} else {
					scope.authStorage = {
						getToken: function () { return "0"; }
					};
				}

				if (scope.afContext) {
					//We support inline image uploads with attached files.
					scope.enableImageUpload = true;
				}

				var getFroalaBarState = function () {
					var barState = {};
					if (localStorage.froalaBarState)
						barState = JSON.parse(localStorage.froalaBarState);
					return barState;
				}
				var getCurrentFroalaBarState = function () {
					var barState = {};
					if (localStorage.froalaBarState)
						barState = JSON.parse(localStorage.froalaBarState);
					return barState[$state.current.name];
				}
				var setFroalaBarState = function () {
					var barState = getFroalaBarState();
					barState[$state.current.name] = isExpanded;
					autoResizeEnabled = isExpanded;
					return localStorage.froalaBarState = JSON.stringify(barState);
				}

				var fullToolbar, foldingToolbar;
				var originalShortOptions = $(element).attr("short-options") != undefined;
				
				if (scope.groupButtons === true) {
					if (originalShortOptions) {
						// For some reason, to get the horizontal divider to work the way we want, everything needs to be wrapped as an array[1][n]
						fullToolbar = [["bold", "italic", "underline", "strikeThrough", "|", "undo", "redo", "fontFamily", "fontSize", "-", "textColor", "backgroundColor", "quote", "html", "clearFormatting", showSignatureButton ? "signatureEditable" : "", "|",
							"align", "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "outdent", "indent", "|", scope.showImageButton ? "insertImage" : "", "insertLink", "insertTable", "|", "expand", "shrink"]];

						foldingToolbar = [["bold", "italic", "underline", "strikeThrough", "|", "undo", "redo", "fontFamily", "fontSize", "-", "textColor", "backgroundColor", "quote", "html", "clearFormatting", showSignatureButton ? "signatureEditable" : "", "|",
							"align", "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "outdent", "indent", "|", scope.showImageButton ? "insertImage" : "", "insertLink", "insertTable", "|", "expand", "shrink"]];
					} else {
						fullToolbar = ["bold", "italic", "underline", "strikeThrough", "|", "undo", "redo", "fontFamily", "fontSize", "textColor", "backgroundColor", "quote", "html", "clearFormatting", showSignatureButton ? "signatureEditable" : "", "|",
							"align", "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "outdent", "indent", "|", scope.showImageButton ? "insertImage" : "", "insertLink", "insertTable", "|", "expand", "shrink"];

						foldingToolbar = ["bold", "italic", "underline", "strikeThrough", "|", "undo", "redo", "fontFamily", "fontSize", "textColor", "backgroundColor", "quote", "html", "clearFormatting", showSignatureButton ? "signatureEditable" : "", "|",
							"align", "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "outdent", "indent", "|", scope.showImageButton ? "insertImage" : "", "insertLink", "insertTable", "|", "expand", "shrink"];
					}
				} else {
					if (originalShortOptions) {
						// For some reason, to get the horizontal divider to work the way we want, everything needs to be wrapped as an array[1][n]
						fullToolbar = [["bold", "italic", "underline", "strikeThrough", "undo", "redo", "fontFamily", "fontSize", "-", "textColor", "backgroundColor", "quote", "html", "clearFormatting", showSignatureButton ? "signatureEditable" : "",
							"align", "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "outdent", "indent", scope.showImageButton ? "insertImage" : "", "insertLink", "insertTable", "expand", "shrink"]];

						foldingToolbar = [["bold", "italic", "underline", "strikeThrough", "undo", "redo", "fontFamily", "fontSize", "-", "textColor", "backgroundColor", "quote", "html", "clearFormatting", showSignatureButton ? "signatureEditable" : "",
							"align", "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "outdent", "indent", scope.showImageButton ? "insertImage" : "", "insertLink", "insertTable", "expand", "shrink"]];
					} else {
						fullToolbar = ["bold", "italic", "underline", "strikeThrough", "undo", "redo", "fontFamily", "fontSize", "textColor", "backgroundColor", "quote", "html", "clearFormatting", showSignatureButton ? "signatureEditable" : "",
							"align", "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "outdent", "indent", scope.showImageButton ? "insertImage" : "", "insertLink", "insertTable", "expand", "shrink"];

						foldingToolbar = ["bold", "italic", "underline", "strikeThrough", "undo", "redo", "fontFamily", "fontSize", "textColor", "backgroundColor", "quote", "html", "clearFormatting", showSignatureButton ? "signatureEditable" : "",
							"align", "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "outdent", "indent", scope.showImageButton ? "insertImage" : "", "insertLink", "insertTable", "expand", "shrink"];
					}
				}

				var userLanguage = getFroalaCompatibleLanguage();
				var toolbar = fullToolbar;
				var minHeight = 200;
				var maxHeight = 500;
				var modalMode = false;
				var isExpanded = getCurrentFroalaBarState() === undefined ? true : getCurrentFroalaBarState();
				var autoResizeEnabled = getCurrentFroalaBarState() === true;
				var shortOptions = false;
				var smallWindowShrinkActive = false;
				var customDropdownCallbacks = {};
				var resizeForModal = function () {
					var modalTabs = $(element).parents("md-tabs-content-wrapper");
					if (modalTabs.length != 0) {
						var h = $(modalTabs[0]).height();
						var newSize = h - 82;
						if (isExpanded)
							newSize = h - 167;
						$(element).find(".fr-wrapper").css("max-height", newSize);
						$(element).find(".fr-element").css("height", newSize);
					}
				}
				var shrinkBar = function (setBarState) {
					isExpanded = false;
					$(".fr-box.fr-basic.fr-top").removeClass("hide-expand");
					$(".fr-box.fr-basic.fr-top").addClass("hide-shrink");
					if (modalMode) {
						resizeForModal();
					}
					if (setBarState)
						setFroalaBarState();
					$rootScope.$broadcast("resize.doResize");
				}

				var expandBar = function (setBarState) {
					isExpanded = true;
					shortOptions = false;
					$(".fr-box.fr-basic.fr-top").removeClass("hide-shrink");
					$(".fr-box.fr-basic.fr-top").addClass("hide-expand");
					if (modalMode) {
						resizeForModal();
					}
					if (setBarState)
						setFroalaBarState();
					$rootScope.$broadcast("resize.doResize");
				}

				var smallWindowShrink = function () {
					if (!autoResizeEnabled) return;
					if (window.innerWidth < 545 && !smallWindowShrinkActive) {
						smallWindowShrinkActive = true;
						shrinkBar();
					} else if (window.innerWidth > 545 && smallWindowShrinkActive) {
						smallWindowShrinkActive = false;
						if (!shortOptions) expandBar();
					}
				}

				if (originalShortOptions) {
					shortOptions = true;
					$timeout(shrinkBar);
				}
				if ($(element).attr("quick-reply") != undefined) {
					minHeight = 100;
					maxHeight = 150;
				}

				$(window).resize(function () {
					smallWindowShrink();

					if (modalMode)
						$timeout(resizeForModal, 400);
				});
				
				FroalaEditor.DefineIcon("expand", { NAME: "plus", template: "font_awesome" });
				FroalaEditor.RegisterCommand("expand", {
					title: $filter("translate")("SHOW_MORE"),
					focus: false,
					undo: false,
					refreshAfterCallback: false,
					callback: function () {
						expandBar(true);
					}
				});
				FroalaEditor.DefineIcon("shrink", { NAME: "minus", template: "font_awesome" });
				FroalaEditor.RegisterCommand("shrink", {
					title: $filter("translate")("SHOW_LESS"),
					focus: false,
					undo: false,
					refreshAfterCallback: false,
					callback: function () {
						shrinkBar(true);
					}
				});

				var signatureEditable = false;
				var btnSignatureEditable = {
					"true": { newIconName: "unlock-alt", newTitle: "LOCK_SIGNATURE" },
					"false": { newIconName: "lock", newTitle: "UNLOCK_SIGNATURE" }
				}
				FroalaEditor.DefineIcon("lockedSignature", { NAME: "lock", template: "font_awesome" });
				FroalaEditor.RegisterCommand("signatureEditable", {
					title: $filter("translate")("UNLOCK_SIGNATURE"),
					icon: "lockedSignature",
					focus: false,
					undo: false,
					callback: function () {
						signatureEditable = !signatureEditable;
						$("#divSignature").attr("contentEditable", signatureEditable);

						changeButtonIcon("signatureEditable", btnSignatureEditable[signatureEditable].newIconName);
						changeButtonTitle("signatureEditable", btnSignatureEditable[signatureEditable].newTitle);
					}
				});

				FroalaEditor.DefineIconTemplate("toolsicon", '<i class="toolsicon toolsicon-[NAME]"></i>');
				
				function changeButtonIcon(button, newIconName) {
					var button = $("[data-cmd=" + button + "] i.fa");
					var oldIconName = button.attr("class").split(" ")[1];
					button.removeClass(oldIconName);
					button.addClass("fa-" + newIconName);
				}

				function changeButtonTitle(button, newTitle) {
					$("[data-cmd=" + button + "]").data().title = $filter("translate")(newTitle);
				}

				function getFroalaCompatibleLanguage() {
					var language = $translate.proposedLanguage() || $translate.use();
					language = language.replace("-", "_").toLowerCase();
					switch (language) {
						case "pt": language += "_pt"; break;
						default: break;
					}
					return language;
				}

				if (scope.customDropdowns && scope.customDropdowns.length > 0) {
					for (var i = 0; i < scope.customDropdowns.length; ++i) {
						if (scope.customDropdowns[i].options) {
							if (scope.customDropdowns[i].callback) {
								customDropdownCallbacks[scope.customDropdowns[i].key] = scope.customDropdowns[i].callback;
							}
							FroalaEditor.DefineIcon(scope.customDropdowns[i].key, { NAME: scope.customDropdowns[i].icon, template: "font_awesome" });
							FroalaEditor.RegisterCommand(scope.customDropdowns[i].key, {
								title: scope.customDropdowns[i].title,
								type: "dropdown",
								focus: false,
								undo: false,
								refreshAfterCallback: true,
								options: scope.customDropdowns[i].options,
								callback: function (cmd, val) {
									if (customDropdownCallbacks[cmd]) {
										customDropdownCallbacks[cmd](cmd, val, scope);
									}
								}
							});

							var customInsertIndex = 21;
							if (scope.groupButtons === true) {
								customInsertIndex += 3;
							}

							if (originalShortOptions) {
								customInsertIndex += 1;
								fullToolbar[0].splice(customInsertIndex, 0, scope.customDropdowns[i].key);
								foldingToolbar[0].splice(customInsertIndex, 0, scope.customDropdowns[i].key);
							} else {
								fullToolbar.splice(customInsertIndex, 0, scope.customDropdowns[i].key);
								foldingToolbar.splice(customInsertIndex, 0, scope.customDropdowns[i].key);
							}
						}
					}
				}

				scope.froalaOptions = {
					//See: https://www.froala.com/wysiwyg-editor/docs/options
					key: "fIE3A-9C1F2D2A5B3B1td1CGHNOa1TNSPH1e1J1VLPUUCVd1FC-22C4A3C3F3D4F2D2C3A2B1==",
					attribution: false,

					charCounterCount: false,
					fontFamily: {
						'arial': "Arial",
						'comic sans ms': "Comic Sans MS",
						'courier new': "Courier New",
						'georgia': "Georgia",
						'lucida sans': "Lucida",
						'tahoma': "Tahoma",
						'times new roman': "Times New Roman",
						'trebuchet ms': "Trebuchet MS",
						'verdana': "Verdana"
					},
					fontSize: ["8", "9", "10", "11", "12", "14", "16", "18", "20", "22", "24", "26", "28", "36", "48", "72"],
					heightMin: minHeight,
					//heightMax: maxHeight,
					// Add the default attributes and the FACE tag - needed to handle font specified by eM Client
					htmlAllowedAttrs: [
						"accept", "accept-charset", "accesskey", "action", "align", "allowfullscreen", "allowtransparency", "alt", "aria-.*", "async", "autocomplete", "autofocus", "autoplay", "autosave",
						"background", "bgcolor", "border",
						"charset", "cellpadding", "cellspacing", "checked", "cite", "class", "color", "cols", "colspan", "content", "contenteditable", "contextmenu", "controls", "coords",
						"data", "data-.*", "datetime", "default", "defer", "dir", "dirname", "disabled", "download", "draggable", "dropzone",
						"enctype",
						"face", "for", "form", "formaction", "frameborder",
						"headers", "height", "hidden", "high", "href", "hreflang", "http-equiv",
						"icon", "id", "ismap", "itemprop",
						"keytype", "kind",
						"label", "lang", "language", "list", "loop", "low",
						"max", "maxlength", "media", "method", "min", "mozallowfullscreen", "multiple", "muted",
						"name", "novalidate",
						"open", "optimum",
						"pattern", "ping", "placeholder", "playsinline", "poster", "preload", "pubdate",
						"radiogroup", "readonly", "rel", "required", "reversed", "rows", "rowspan",
						"sandbox", "scope", "scoped", "scrolling", "seamless", "selected", "shape", "size", "sizes", "span", "src", "srcdoc", "srclang", "srcset", "start", "step", "summary", "spellcheck", "style",
						"tabindex", "target", "title", "type", "translate",
						"usemap",
						"value", "valign",
						"webkitallowfullscreen", "width", "wrap"
					],
					// Add the default tags and the FONT tag - needed to handle some styles from eM Client
					htmlAllowedTags: [
						"a", "abbr", "address", "area", "article", "aside", "audio",
						"b", "base", "bdi", "bdo", "blockquote", "br", "button",
						"canvas", "caption", "cite", "code", "col", "colgroup",
						"datalist", "dd", "del", "details", "dfn", "dialog", "div", "dl", "dt",
						"em", "embed",
						"fieldset", "figcaption", "figure", "font", "footer", "form",
						"h1", "h2", "h3", "h4", "h5", "h6", "header", "hgroup", "hr",
						"i", "iframe", "img", "input", "ins",
						"kbd", "keygen",
						"label", "legend", "li", "link",
						"main", "map", "mark", "menu", "menuitem", "meter",
						"nav", "noscript",
						"object", "ol", "optgroup", "option", "output",
						"p", "param", "pre", "progress",
						"queue",
						"rp", "rt", "ruby",
						"s", "samp", "script", "style", "section", "select", "small", "source", "span", "strike", "strong", "sub", "summary", "sup",
						"table", "tbody", "td", "textarea", "tfoot", "th", "thead", "time", "title", "tr", "track",
						"u", "ul",
						"var", "video",
						"wbr"
					],
					imageInsertButtons: ["imageBack", "|", "imageUpload", "imageByURL"],
					imageUploadRemoteUrls: false,
					language: userLanguage,
					listAdvancedTypes: false,
					pastePlain: false,
					placeholderText: "",
					quickInsertTags: [],		// Disable the 'Quick Insert' button
					requestHeaders: { Authorization: 'Bearer ' + scope.authStorage.getToken() },
					tableStyles: {
						"st-no-borders": "No Borders",
						"fr-dashed-borders": "Dashed Borders",
						"fr-alternate-rows": "Alternate Rows"
					},
					tabSpaces: 4,
					toolbarButtons: toolbar,
					toolbarButtonsMD: toolbar,
					toolbarButtonsSM: toolbar,
					toolbarButtonsXS: foldingToolbar,
					toolbarSticky: false,
					useClasses: false,
					wordPasteModal: false
				}

				tryRefreshAuth();

				scope.froalaOptions.imageUploadMethod = "POST";

				if (scope.attachGuid)
					scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/mail/attachment/" + scope.attachGuid + "/cidgenerate";
				else if (scope.afContext) {
					//var afInfo = "/" + afInfo + "/";
					scope.froalaOptions.imageUploadParams = {
						afSourceType: scope.afSourceType,
						afSourceId1: scope.afSourceId1,
						afSourceId2: scope.afSourceId2,
						afDomainOverride: scope.afDomainOverride,
						isInline: true
					};

					if (scope.afContext == "system")
						scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/settings/sysadmin/attachedfile/cidgenerate";
					else if (scope.afContext == "domain") {
						if (scope.afSourceType == "ap")
							scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/settings/attachedfile/cidgenerate";
						else
							scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/settings/domain/attachedfile/cidgenerate";
					}
					else if (scope.afContext == "domainshare") {
						scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/settings/attached-domain-shared-file/cidgenerate";
                    }
					else// if (scope.afContext == "user")
						scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/settings/attachedfile/cidgenerate";
				}
				if (!scope.enableImageUpload) {
					scope.froalaOptions.imageUploadURL = undefined;
				}
				if (scope.htmlContent == undefined)
					scope.htmlContent = "";

				//This will replace any attached file links with the imgsrc equivalent, so it renders correctly.
				//This works universally because of this code here.
				if (scope.htmlContent.indexOf("attlinkedfileid:") > -1) {
					scope.htmlContent = scope.htmlContent.split('\"attlinkedfileid:').join('"' + location.origin + stSiteRoot + 'attachedfile?data=');
				}

				if (!isNaN(scope.height)) {
					scope.froalaOptions.heightMin = parseInt(scope.height);
					scope.froalaOptions.heightMax = parseInt(scope.height);
				}
				if (!isNaN(scope.minHeight)) {
					scope.froalaOptions.heightMin = parseInt(scope.minHeight);
				}
				if (!isNaN(scope.maxHeight)) {
					scope.froalaOptions.heightMax = parseInt(scope.maxHeight);
				} else if (scope.maxHeight === 'unset') {
					scope.froalaOptions.heightMax = null;
				}

				if (!scope.enterAction) {
					scope.froalaOptions.enter = FroalaEditor.ENTER_DIV;
				} else {
					switch (scope.enterAction) {
						case "p":
							break;
						case "div":
							scope.froalaOptions.enter = FroalaEditor.ENTER_DIV;
							break;
						case "br":
							scope.froalaOptions.enter = FroalaEditor.ENTER_BR;
							break;
					}
				}
				if (scope.tabSpaces != undefined) {
					scope.froalaOptions.tabSpaces = scope.tabSpaces;
				} else if (scope.tabSpaces == false) {
					scope.froalaOptions.tabSpaces = false;
				}

				var getDefer = null;
				function getEditor() {
					if (!getDefer) {
						getDefer = $q.defer();
						$timeout(getEditorRecursive);
					}
					return getDefer.promise;

					function getEditorRecursive() {
						if (scope.editor == undefined) {
							$timeout(function() { getEditorRecursive() }, 20);
							return;
						}

						getDefer.resolve(scope.editor);
						getDefer = null;
					}
				}

				scope.getEditor = getEditor;

				scope.insertHtml = function (html, shouldBeCleaned) {
					// Inserts HTML at the cursor's position
					return getEditor().then(function (editor) {
						editor.html.insert(html, shouldBeCleaned == undefined ? true : shouldBeCleaned);
						editor.undo.saveStep();
					}, function () { });
				}

				scope.getHtml = function (keepMarkers) {
					keepMarkers = keepMarkers || false;
					const $body = $("body");
					const restoreDark = $body.hasClass("theme-dark");
					$body.removeClass("theme-dark");
					try {
						if (scope.stDisabled) {
							const roElem = $(element).find("div.st-readonly");
							if (roElem)
								return roElem.html();
						}

						const editor = scope.editor;
						if (editor) {
							let html = scope.editor.html.get(keepMarkers);
							if (editor.codeView.isActive()) {
								// Do this instead of codeView.get() because this approach triggers some of froala's sanitization
								editor.codeView.toggle();
								html = scope.editor.html.get(keepMarkers);
								editor.codeView.toggle();
							}
							return html;
						}

						return "";
					} finally {
						if (restoreDark)
							$body.addClass("theme-dark");
					}
				}

				scope.setHtml = function (html) {
					// Clears out contents and sets them to the html passed in
					return getEditor().then(function (editor) {
						if (scope.stDisabled) {
							const roElem = $(element).find("div.st-readonly");
							if (roElem) roElem.html(html);
						} else {
							editor.html.set(html);
							editor.undo.saveStep();
						}
					});
				}
				scope.setHtmlPending = false;
				scope.setHtmlPromise = function(html, saveStep) {
					if (scope.stDisabled) {
						const roElem = $(element).find("div.st-readonly");
						if (roElem) roElem.html(html);
						return $q.resolve();
					} else {
						if (scope.setHtmlPending) return $q.reject("Edit Pending");
						scope.setHtmlPending = true;

						const defer = $q.defer();
						const onSetHtmlComplete = function(e) {
							delete scope.editor.opts.events["html.set"];
							scope.setHtmlPending = false;
							if (saveStep) scope.editor.undo.saveStep();
							defer.resolve();
						};
						getEditor().then(function(editor) {
								editor.opts.events["html.set"] = onSetHtmlComplete;
								editor.html.set(html);
							},
							function(err) {
								return $q.reject(err);
							});
						return defer.promise;
					}
				}
				scope.insertFileLink = function(source, text, attributes) {
					if (!scope.stDisabled && scope.enableBlur && element.find(".fr-marker").length > 0) {
						scope.editor.selection.restore();
					}
					attributes = attributes || { "target": "_blank" };
					text = text || scope.editor.selection.text() || source;
					scope.editor.link.insert(source, text, attributes);
					scope.editor.selection.clear();
				}

				scope.focusEditor = function() {
					////element.focus();
					if (!scope.stDisabled && scope.enableBlur && element.find(".fr-marker").length > 0) {
						console.log("setting focus restore");
						scope.editor.selection.restore();
					}
					else if (!scope.stDisabled) {
						console.log("setting focus");
						scope.editor.events.focus();
					} 
					else {
						element.focus();
					}
				}
				
				scope.restoreSelection = function () {
					if (!scope.stDisabled && scope.enableBlur) {
						scope.editor.selection.restore();
					}
				}

				scope.clearEditor = function () {
					getEditor().then(function (editor) { editor.html.set(""); editor.undo.saveStep(); });
				}

				scope.setSaveInterval = function (interval) {
					scope.froalaOptions.saveInterval = scope.saveDraft.saveInterval = interval;
				}

				var limit = 0;
				function disableFroala() {
					const editor = $(element).find("textarea.froalaEditor")[0]["data-froala.editor"];
					if (editor && editor.edit) {
						const roElem = $(element).find("div.st-readonly");
						if (scope.stDisabled) {
							roElem.html(editor.html.get());
							roElem.css("display", "");

							editor.edit.off();
							editor.$box.css("display", "none");
						}
						else {
							roElem.html("");
							roElem.css("display", "none");

							editor.edit.on();
							editor.$box.css("display", "");
						}
						limit = 0;
					} else {
						if (limit < 20) {
							++limit;
							$timeout(disableFroala, 100);
						} else {
							$timeout(function () { limit = 0; }, 500);
						}
					}

					if (scope.stDisabled) {
						element.append(angular.element(`<style style="text/css" src="${stSiteRoot}interface/output/froala-language/${userLanguage}.js"></script>`));
					}
				}

				scope.$watch("stDisabled", disableFroala);
				
				if ($(element).attr("save-draft") != undefined) {
					scope.froalaOptions.saveParam = scope.saveDraft.saveParam;
					scope.froalaOptions.saveParams = scope.saveDraft.saveParams;
					scope.froalaOptions.saveMethod = scope.saveDraft.saveMethod;
					scope.froalaOptions.saveInterval = scope.saveDraft.saveInterval;
					scope.froalaOptions.saveURL = scope.saveDraft.saveURL;
				}

				scope.initEditor = function (controls) {
					if (!scope.froalaOptions.events) scope.froalaOptions.events = {};
					scope.froalaOptions.events["initialized"] = function() {
						initEditor2(controls);
					};

					// This timeout is necessary to make sure that the controls are initialized after the
					// target element is compiled and inserted into the page.
					$timeout(function() { controls.initialize(); });
				}
				
				function initEditor2(controls) {
					if (scope.stDisabled)
						disableFroala();

					scope.editorControls = controls;
					scope.editor = controls.getEditor();
					if ($(element).attr("short-options") != undefined) {
						shrinkBar();
					}
					else {
						if (isExpanded)
							expandBar();
						else
							shrinkBar();
					}

					if (scope.authStorage.getToken() == "0") {
						if ($(element).injector()) {
							if ($(element).injector().has('authStorage')) {
								scope.authStorage = $(element).injector().get('authStorage');
							}
							else {
								scope.authStorage = {
									getToken: function () { return "0"; }
								};
							}
						} else {
							scope.authStorage = {
								getToken: function () { return "0"; }
							};
						}

						scope.editor.opts.requestHeaders.Authorization = 'Bearer ' + scope.authStorage.getToken();
					}

					// This fixes the issue where the horizontal separator wasn't appearing correctly
					if (window.innerWidth >= 768)
						$(element).find(".fr-separator").eq(1).removeClass("fr-vs").addClass("fr-hs");

					scope.editor.undo.reset();
					var firstChange = true;

					scope.editor.opts.events["keydown"] = function(e, editor) { onFirstChange(); };
					scope.editor.opts.events["paste.after"] = function(e, editor) { onFirstChange(); };

					//scope.editor.opts.events["image.inserted"] = function (img, response) { scope.onImageInserted(img, response); };

					scope.editor.opts.fileUpload = false; //Disable file uploading.

					// Bind saving events
					if ($(element).attr("save-draft") != undefined) {
						if (scope.saveDraft.onSaveSuccess)
							scope.editor.opts.events["save.after"] = scope.saveDraft.onSaveSuccess;
						if (scope.saveDraft.onSaveFailure)
							scope.editor.opts.events["save.error"] = scope.saveDraft.onSaveFailure;
						if (scope.saveDraft.onBeforeSave)
							scope.editor.opts.events["save.before"] = scope.saveDraft.onBeforeSave;
					}

					// Adjust dropdowns in modals that go off the side of the modal
					var dropdowns = $("md-dialog .fr-dropdown");
					if (dropdowns.length > 0) {
						dropdowns.click(function (ev) {
							var dropdownId = "#dropdown-menu-" + ev.currentTarget.id;
							var modalWidth = $("md-dialog").width();
							var width = $(dropdownId).width() + 40;
							var left = $(dropdownId).css("left");
							left = Number(left.substring(0, left.length - 2)); // Remove the 'px' at the end of the string so we can make it a number

							if (left + width <= modalWidth) return;

							left -= (width - 78);
							$(dropdownId).css("left", left + "px");
						});
					}

					scope.editor.opts.events["commands.after"] = function(e, editor, cmd, param1) {
						if (cmd == "paragraphFormat" && param1 == "PRE") {
							var snippet = $(".fr-view pre:not(.code-snippet)");
							if (snippet.length > 0) {
								snippet.attr("style",
									"background-color: #F8F8F8; border: 1px solid #DDD; padding: 10px; display: inline-block; margin-right: 20px;");
								snippet.addClass("code-snippet");
							}
						} else if (cmd == "paragraphFormat" && param1 !== "PRE") {
							var snippet = $(".fr-view .code-snippet:not(pre)");
							if (snippet.length > 0) {
								snippet.attr("style", "");
								snippet.removeClass("code-snippet");
							}
						}
						if (firstChange) {
							onFirstChange();
							firstChange = false;
						}
					};
					if (typeof scope.onImageRemoved === "function") {
						scope.editor.opts.events["image.removed"] = scope.onImageRemoved;
					}
					if (!scope.enableImageUpload) {
						scope.editor.opts.events["image.beforeUpload"] = function(files) {
							var editor = this;
							// Create a File Reader.
							if (files.length) {

								const reader = new FileReader();

								// Set the reader to insert images when they are loaded.
								reader.onload = function(e) {
									const result = e.target.result;
									editor.image.insert(result, null, null, editor.image.get());
								};

								// Read image as base64.
								reader.readAsDataURL(files[0]);
							}
							editor.popups.hideAll();
							return false;
						}
						scope.editor.opts.events["image.inserted"] = function(img, response) {
							if (typeof scope.onImageInserted === "function") 
								scope.onImageInserted(img, response);
						}
					} else if (!scope.attachGuid && !scope.afContext) {
						scope.editor.opts.events["image.beforeUpload"] = function(blob, image, files) {
							var editor = this;
							// Remove the data-fr-image-pasted attribute from any existing images.
							//$("img[data-fr-image-pasted]").removeAttr("data-fr-image-pasted");

							if (files && files.length) {
								// Create a File Reader.
								const reader = new FileReader();

								// Set the reader to insert images when they are loaded.
								reader.onload = function(e) {
									const result = e.target.result;
									editor.image.insert(result, null, null, editor.image.get());
								};

								// Read image as base64.
								reader.readAsDataURL(files[0]);
							}

							// Stop default upload chain.
							return false;
						}
					} else if (scope.afContext) {

						scope.editor.opts.events["image.inserted"] = function (img, response) {
							if (firstChange) {
								onFirstChange();
								firstChange = false;
							}
							if (scope.onImageInserted)
								scope.onImageInserted(img, response);
						};
					} 

					scope.editor.opts.events["image.error"] = function (error, response) {
						if (error.code === 5 || JSON.parse(response).message === "EXCEPTION_FILE_SIZE_LIMIT_EXCEEDED") {
							var popup = scope.editor.popups.get("image.insert")
							var layer = popup.find(".fr-image-progress-bar-layer");
							layer.find("h3").text($translate.instant("EXCEPTION_FILE_SIZE_LIMIT_EXCEEDED"));
						}
					}

					function onFirstChange() {
						delete scope.editor.opts.events["keydown"];
						delete scope.editor.opts.events["paste.after"];
						scope.stFirstChange();
						if (!scope.stDisabled && scope.enableBlur) {
							// NOTE: Attempting to save the selection on blur (lost focus) causes jumping cursor issues with Froala.
							//		NOT being able to do this means that the "Link File" function of message compose will insert the link at the beginning of the message instead of at the last cursor position
							scope.editor.opts.events["blur"] = function(e) {
								scope.editor.selection.save();
							};
							scope.editor.opts.events["click"] = function(e) {
								// This is a hack to fix froala jumping cursor bug by resetting the selection to current cursor position;
								// NOTE: the clear alone does not work
								// scope.editor.selection.clear();  // this actually creates problems
								scope.editor.selection.save();
							};
						} 
					}

					//look for md-dialog-content if we need this functionality in a modal without tabs
					var modalTabs = $(element).parents("md-tabs-content-wrapper");
					if (modalTabs.length != 0) {
						modalMode = true;
						$timeout(resizeForModal);
					}
					smallWindowShrink();

					if (typeof scope.onInitialized == "function")
						scope.onInitialized(scope.editor);
				}

				if (userLanguage != "en") {
					element.append(angular.element('<script src=" ' + stSiteRoot + "interface/output/froala-language/" + userLanguage + '.js"></script>'));

					$timeout(() => provideMissingTranslations(userLanguage, FroalaEditor));
				}

				var textAreaTemplate =
					`<textarea class="froalaEditor" froala="froalaOptions" ng-model="htmlContent" froala-init="initEditor(initControls)" ${carteac != undefined ? `st-CARTEAC${carteac != "" ? `="${carteac}"` : ""}` : ""}>{{htmlContent}}</textarea>`;
				var elementToBeAdded = angular.element(textAreaTemplate);
				var elementToBeAddedCompiled = $compile(elementToBeAdded)(scope);
				element.append(elementToBeAddedCompiled);
				
				// Using the froala styles for the read only field will make the styles consistent with the actual editor.
				var readOnlyTemplate = scope.htmlTrustFunction
					? "<div class=\"st-readonly fr-box fr-basic fr-top\" style=\"display: none;\" ng-bind-html=\"htmlTrustFunction(htmlContent)\"></div>"
					: "<div class=\"st-readonly fr-box fr-basic fr-top\" style=\"display: none;\" ng-bind-html=\"htmlContent\"></div>";
				elementToBeAdded = angular.element(readOnlyTemplate);
				elementToBeAddedCompiled = $compile(elementToBeAdded)(scope);
				element.append(elementToBeAddedCompiled);
				
				scope.destroyEditor = function () {
					scope.froalaOptions.saveParam = "";
					scope.froalaOptions.saveParams = "";
					scope.froalaOptions.saveMethod = "";
					scope.froalaOptions.saveInterval = 0;
					scope.froalaOptions.saveURL = "";
					scope.editorControls.destroy();
				}
				scope.editorScope = scope;
				scope.editorScopeAux = scope;
				
				scope.$watch("afContext", afContextChanged);
				function afContextChanged() {
					if (scope.attachGuid)
						scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/mail/attachment/" + scope.attachGuid + "/cidgenerate";
					else if (!scope.preventImageUpload) {
						if (scope.afContext == "system")
							scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/settings/sysadmin/attachedfile/cidgenerate";
						else if (scope.afContext == "domain") {
							if (scope.afSourceType == "ap")
								scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/settings/attachedfile/cidgenerate";
							else
								scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/settings/domain/attachedfile/cidgenerate";
						}
						else if (scope.afContext == "domainshare") {
							scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/settings/attached-domain-shared-file/cidgenerate";
						}
						else
							scope.froalaOptions.imageUploadURL = window.location.origin + stSiteRoot + "api/v1/settings/attachedfile/cidgenerate";

						const editor = $(element).find("textarea.froalaEditor")[0]["data-froala.editor"];
						if (editor)
							editor.opts.imageUploadURL = scope.froalaOptions.imageUploadURL;
					}
				}

				var refreshTokenDefer = null;
				function tryRefreshAuth() {
					if (refreshTokenDefer)
						return;

					if (!scope.authStorage.getAccessTokenExpiration) {
						$timeout(tryRefreshAuth, 20);
						return;
					}

					const expireBuffer = 60000;

					var now = new Date().getTime();
					var expires = new Date(scope.authStorage.getAccessTokenExpiration());
					expires = expires.getTime() - expireBuffer;
					if (now > expires) {
						if (scope.authStorage.isTokenCurrentlyUpdating()) {
							$timeout(tryRefreshAuth, 20);
							return;
						}

						scope.authStorage.updatingToken(true);
						var refreshToken = scope.authStorage.getRefreshToken();
						if (!refreshToken) {
							console.debug("Blank refresh token in st-html-editor");
						}

						var clientId = scope.authStorage.getClientId();

						refreshTokenDefer = $http.post("~/api/v1/auth/refresh-token", { token: refreshToken, iswebmailrefresh: true, clientId: clientId })
							.then(
								function () {
									refreshTokenDefer = null;
									console.debug("Editor refreshed token.");
									scope.froalaOptions.requestHeaders.Authorization = 'Bearer ' + scope.authStorage.getToken();
									if (scope.editor)
										scope.editor.opts.requestHeaders.Authorization = 'Bearer ' + scope.authStorage.getToken();

									now = new Date().getTime();
									expires = new Date(scope.authStorage.getAccessTokenExpiration());
									expires = expires.getTime() - expireBuffer;
									var delay = expires - now;
									$timeout(tryRefreshAuth, delay);
								},
								function () {
									scope.authStorage.updatingToken(false);
									refreshTokenDefer = null;
									console.warn("Editor refresh token failed.");
								})
					}
					else {
						scope.froalaOptions.requestHeaders.Authorization = 'Bearer ' + scope.authStorage.getToken();
						if (scope.editor)
							scope.editor.opts.requestHeaders.Authorization = 'Bearer ' + scope.authStorage.getToken();

						var delay = expires - now;
						$timeout(tryRefreshAuth, delay);
					}
				}

				function provideMissingTranslations(userLang, editor) {
					if (userLang === "en")
						return;

					if (userLang === "en_gb" && editor.LANGUAGE.en_gb)
						editor.LANGUAGE.en_gb.translation["Cell Formatting"] = "Clear Formatting";

					var lang = editor.LANGUAGE[userLang];
					if (!lang || !lang.translation)
						return;

					lang.translation["Alternate Rows"] = $translate.instant("FROALA_ALTERNATE_ROWS");
					lang.translation["Bordered"] = $translate.instant("FROALA_BORDERED");
					lang.translation["Dashed Borders"] = $translate.instant("FROALA_DASHED_BORDERS");
					lang.translation["Gray"] = $translate.instant("FROALA_GRAY");
					lang.translation["Highlighted"] = $translate.instant("FROALA_HIGHLIGHTED");
					lang.translation["No Borders"] = $translate.instant("FROALA_NO_BORDERS");
					lang.translation["Spaced"] = $translate.instant("FROALA_SPACED");
					lang.translation["Thick"] = $translate.instant("FROALA_THICK");
					lang.translation["Uppercase"] = $translate.instant("FROALA_UPPERCASE");
				}
			}
		}
	});
})();

//Ensure element has an id then create editor tied to this instance
//$(element).uniqueId();
//var uid = $(element).attr('id');

//<div text-angular ng-model="htmlContent" st-CARTEAC  ta-toolbar="[[\'h1\',\'h2\',\'h3\'],[\'bold\',\'italics\',\'fontColor\']]" name="editor-' + uid + '"></div>\

//$provide.decorator('taOptions', ['taRegisterTool', '$delegate', function(taRegisterTool, taOptions){
//	// $delegate is the taOptions we are decorating
//	// register the tool with textAngular

//	taRegisterTool('backgroundColor', {
//		display: "<button colorpicker class='btn btn-default ng-scope' title='Background Color' type='button' colorpicker-close-on-select colorpicker-position='bottom' ng-model='backgroundColor' style='background-color: {{backgroundColor}}'><i class='fa fa-paint-brush'></i></button>",
//		action: function (deferred) {
//			var self = this;
//			this.$editor().wrapSelection('backgroundColor', this.backgroundColor);
//			if (typeof self.listener == 'undefined') {
//				self.listener = self.$watch('backgroundColor', function (newValue) {
//					self.$editor().wrapSelection('backColor', newValue);
//				});
//			}
//			self.$on('colorpicker-selected', function () {
//				deferred.resolve();
//			});
//			self.$on('colorpicker-closed', function () {
//				deferred.resolve();
//			});
//			return;
//		}
//	});
//	taOptions.toolbar[1].unshift('backgroundColor');

//	taRegisterTool('fontColor', {
//		display: "<button colorpicker type='button' class='btn btn-default ng-scope'  title='Font Color'  colorpicker-close-on-select colorpicker-position='bottom' ng-model='fontColor' style='color: {{fontColor}}'><i class='fa fa-font '></i></button>",
//		action: function (deferred) {
//			var self = this;
//			if (typeof self.listener == 'undefined') {
//				self.listener = self.$watch('fontColor', function (newValue) {
//					self.$editor().wrapSelection('foreColor', newValue);
//				});
//			}
//			self.$on('colorpicker-selected', function () {
//				deferred.resolve();
//			});
//			self.$on('colorpicker-closed', function () {
//				deferred.resolve();
//			});
//			return false;
//		}
//	});
//	taOptions.toolbar[1].unshift('fontColor');

//	taOptions.setup.textEditorSetup=function($element) {
//		$element.attr('ui-codemirror', '');
//	};
//	return taOptions;
//}]);