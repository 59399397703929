(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminManageViewLogsController", sysAdminManageViewLogsController);



	function sysAdminManageViewLogsController($rootScope, $scope, $http, $filter, $q, $mdDialog, $translate, $timeout,
		coreData, coreDataSysAdminSettings, coreDataManageSettings, coreDataFileStorage, coreDataSysAdminCards, errorHandling, successHandling) {

		var vm = this;
		vm.cardService = coreDataSysAdminCards;
		vm.manageCore = coreDataManageSettings;
		vm.startDate = new Date();
		vm.endDate = new Date();
		vm.searchString = "";
		if (window.stOS == "L") {
			vm.typeOptions = [
				{ translate: "ADMINISTRATIVE", value: "administrative", showRelated: false },
				{ translate: "AUTODISCOVER", value: "autodiscover", showRelated: false },
				{ translate: "CALENDARS", value: "calendars", showRelated: false },
				{ translate: "CERTIFICATES", value: "certificates", showRelated: false },
				{ translate: "CONTENT_FILTER", value: "contentfilter", showRelated: false },
				{ translate: "CONVERSION", value: "conversion", showRelated: false },
				{ translate: "DELIVERY", value: "delivery", showRelated: true },
				{ translate: "ACTIVESYNC", value: "activeSync", showRelated: true },
				{ translate: "ERROR", value: "generalErrors", showRelated: false },
				{ translate: "EVENTS", value: "event", showRelated: false },
				{ translate: "EWS", value: "ews", showRelated: false },
				{ translate: "EWS_RETRIEVAL", value: "ewsRetrieval", showRelated: false },
				{ translate: "FOLDER_AUTO_CLEAN", value: "autoCleanFolders", showRelated: false },
				{ translate: "IMAP", value: "imapLog", showRelated: true },
				{ translate: "IMAP_RETRIEVAL", value: "imapRetrieval", showRelated: true },
				{ translate: "INDEXING", value: "indexing", showRelated: false },
				{ translate: "LDAP", value: "ldapLog", showRelated: true },
				{ translate: "LICENSING", value: "activation", showRelated: false },
				{ translate: "MAILING_LISTS", value: "mailinglists", showRelated: false },
				{ translate: "MAINTENANCE", value: "maintenance", showRelated: false },
				{ translate: "MAPI", value: "mapi", showRelated: false },
				{ translate: "MESSAGE_ID_HYPEN", value: "messageId", showRelated: true },
				{ translate: "OAB", value: "oab", showRelated: false },
				{ translate: "POP", value: "popLog", showRelated: true },
				{ translate: "POP_RETRIEVAL", value: "popRetrieval", showRelated: true },
				{ translate: "SHAREPOINT", value: "sharePoint", showRelated: false },
				{ translate: "SMTP", value: "smtpLog", showRelated: true },
				{ translate: "SPAM_CHECKS", value: "spamChecks", showRelated: true },
				{ translate: "WEBDAV", value: "webdav", showRelated: true },
				{ translate: "XMPP", value: "xmppLog", showRelated: false }
			];
		} else {
			vm.typeOptions = [
				{ translate: "ADMINISTRATIVE", value: "administrative", showRelated: false },
				{ translate: "AUTODISCOVER", value: "autodiscover", showRelated: false },
				{ translate: "CALENDARS", value: "calendars", showRelated: false },
				{ translate: "CERTIFICATES", value: "certificates", showRelated: false },
				{ translate: "CONTENT_FILTER", value: "contentfilter", showRelated: false },
				{ translate: "CONVERSION", value: "conversion", showRelated: false },
				{ translate: "DELIVERY", value: "delivery", showRelated: true },
				{ translate: "ACTIVESYNC", value: "activeSync", showRelated: true },
				{ translate: "ERROR", value: "generalErrors", showRelated: false },
				{ translate: "EVENTS", value: "event", showRelated: false },
				{ translate: "EWS", value: "ews", showRelated: false },
				{ translate: "EWS_RETRIEVAL", value: "ewsRetrieval", showRelated: false },
				{ translate: "FOLDER_AUTO_CLEAN", value: "autoCleanFolders", showRelated: false },
				{ translate: "IIS", value: "errorlog", showRelated: false },
				{ translate: "IMAP", value: "imapLog", showRelated: true },
				{ translate: "IMAP_RETRIEVAL", value: "imapRetrieval", showRelated: true },
				{ translate: "INDEXING", value: "indexing", showRelated: false },
				{ translate: "LDAP", value: "ldapLog", showRelated: true },
				{ translate: "LICENSING", value: "activation", showRelated: false },
				{ translate: "MAILING_LISTS", value: "mailinglists", showRelated: false },
				{ translate: "MAINTENANCE", value: "maintenance", showRelated: false },
				{ translate: "MAPI", value: "mapi", showRelated: false },
				{ translate: "MESSAGE_ID_HYPEN", value: "messageId", showRelated: true },
				{ translate: "OAB", value: "oab", showRelated: false },
				{ translate: "POP", value: "popLog", showRelated: true },
				{ translate: "POP_RETRIEVAL", value: "popRetrieval", showRelated: true },
				{ translate: "SHAREPOINT", value: "sharePoint", showRelated: false },
				{ translate: "SMTP", value: "smtpLog", showRelated: true },
				{ translate: "SPAM_CHECKS", value: "spamChecks", showRelated: true },
				{ translate: "WEBDAV", value: "webdav", showRelated: true },
				{ translate: "XMPP", value: "xmppLog", showRelated: false }
			];
		}

		vm.currentType = $.grep(vm.typeOptions, function(f) { return f.translate === "DELIVERY"; })[0];// $scope.typeOptions[4];
		vm.relatedResult = false;
		vm.searchResult = false;
		vm.showMenu = false;
		vm.isTruncated = false;
		var textArea = null;
		var lastRelatedResult = vm.relatedResult;
		vm.atBottom = false;

		// Functions
		vm.copyLinkNotification = copyLinkNotification;
		vm.jumpTo = jumpTo;
		vm.onTypeChange = () => { 
			vm.relatedResult = vm.currentType.showRelated ? lastRelatedResult : false;
		};
		vm.onRelatedResultChange = () => { 
			if (vm.currentType.showRelated) lastRelatedResult = vm.relatedResult; 
		};
		var onScrollThrottled = _.throttle(onTextScroll, 50);
		activate();

		/////////////////////

		function activate() {
			//$scope.mainSetCtrl.onBackButtonClickedDelegate = backToSearch;
			var promises = [
				coreData.init(),
				$http.get("~/api/v1/settings/sysadmin/global-mail")];
			$q.all(promises)
				.then(function (result) {
					coreDataSysAdminSettings.searchEnabled = false;
					coreDataManageSettings.searchLogs = searchLogs;
					coreDataManageSettings.downloadLogs = downloadLogs;
					coreDataManageSettings.DownloadLogFilesSearched = DownloadLogFilesSearched;
					coreDataManageSettings.onDownloadMenuOpen = onDownloadMenuOpen;
					$scope.$emit("troubleshooting:countersChanged");
					var ids = angular.copy(result[1].data.globalMailSettings.logSettings.debugLogIds || []);
					ids.forEach(function (id) {
						vm.typeOptions.push({ translate: id, value: id, showRelated: false });
					});
				}, errorHandling.report);

			//$scope.$watch("startDate", function () {
			//	if ($scope.startDate > $scope.endDate)
			//		$scope.endDate = $scope.startDate;
			//});

			//$scope.$watch("endDate", function () {
			//	if ($scope.startDate > $scope.endDate)
			//		$scope.endDate = $scope.startDate;
			//});

			$(".copyAllTarget").scroll(onScrollThrottled);
		}

		function onDownloadMenuOpen() {
			if (vm.showMenu == true)  {
				vm.showMenu = false;
			} else {
				vm.showMenu = true;
			}
			return;
			
		}
		function onTextScroll() {
			if (this.scrollHeight <= (this.scrollTop + this.offsetHeight)) {
				vm.atBottom = true;
				$scope.$applyAsync();
				return;
			}
			var flag = vm.atBottom;
			vm.atBottom = false;
			if (flag) { $scope.$applyAsync(); }
		}

		function searchLogs() {
			$rootScope.spinner.show();

			if (!vm.startDate) { vm.startDate = new Date(); }
			if (!vm.endDate) { vm.endDate = new Date(); }
			var params = JSON.stringify({ start: vm.startDate, end: vm.endDate, type: vm.currentType.value, search: vm.searchString, related: vm.relatedResult });
			$http
				.post("~/api/v1/settings/sysadmin/log-files", params)
				.then(function (success) {
					vm.isTruncated = success.data.isTruncated || false;
					vm.searchResult = true;
					$(".copyAllTarget")[0].value = success.data.result;
					if (!success.data.result) {
						errorHandling.warn("LOGS_AND_SETTINGS_ERRORS_GET_LOG_RESULTS");
					}
				}, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}

		function downloadLogs() {
			var progressTimeout = $timeout(function () {
				var progress = $mdDialog.stProgressBar()
					.title($translate.instant("DOWNLOADING"))
					.barText("")
					.close($translate.instant("DOWNLOAD_IN_BACKGROUND"))
					.showCancel(false)
					.enableButtons(true)
					.autoClose(true)
					.percentProgress(-1)
					.guid("logs-download")
					.progressListener("signalR.mailHub.client.logDownloadProg");
				$mdDialog.show(progress);
			}, 1000);

			var httpPath = "~/api/v1/settings/sysadmin/download-log-files";
			if (!vm.startDate) { vm.startDate = new Date(); }
			if (!vm.endDate) { vm.endDate = new Date(); }
			var fileName = $filter("translate")(vm.currentType.translate) + "-logs.zip";
			var params = JSON.stringify({ start: vm.startDate, end: vm.endDate, type: vm.currentType.value, search: vm.searchString, related: vm.relatedResult });

			coreDataFileStorage
				.downloadFile(httpPath, fileName, params)
				.then(function() {
					}, function (failure) {
						if (failure.message && failure.message === "LOGS_AND_SETTINGS_ERRORS_GET_LOG_RESULTS")
							errorHandling.warn(failure);
						else
							errorHandling.report(failure);
					}).finally(function () {
						$rootScope.spinner.hide();
						$rootScope.$broadcast("signalR.mailHub.client.logDownloadProg", { guid: "logs-download", current: -1 });
						if (progressTimeout) $timeout.cancel(progressTimeout);
					});
		}

		function DownloadLogFilesSearched() {
			var progressTimeout = $timeout(function () {
				var progress = $mdDialog.stProgressBar()
					.title($translate.instant("DOWNLOADING"))
					.barText("")
					.close($translate.instant("DOWNLOAD_IN_BACKGROUND"))
					.showCancel(false)
					.enableButtons(true)
					.autoClose(true)
					.percentProgress(-1)
					.guid("logs-download")
					.progressListener("signalR.mailHub.client.logDownloadProg");
				$mdDialog.show(progress);
			}, 1000);
			var httpPath = "~/api/v1/settings/sysadmin/download-log-files-searched";
			if (!vm.startDate) { vm.startDate = new Date(); }
			if (!vm.endDate) { vm.endDate = new Date(); }
			var fileName = $filter("translate")(vm.currentType.translate) + "-logs.zip";
			var params = JSON.stringify({ start: vm.startDate, end: vm.endDate, type: vm.currentType.value, search: vm.searchString, related: vm.relatedResult });

			coreDataFileStorage
				.downloadFile(httpPath, fileName, params)
				.then(function () {
				}, function (failure) {
					if (failure.message && failure.message === "LOGS_AND_SETTINGS_ERRORS_GET_LOG_RESULTS")
						errorHandling.warn(failure);
					else
						errorHandling.report(failure);
				}).finally(function () {
					$rootScope.spinner.hide();
					$rootScope.$broadcast("signalR.mailHub.client.logDownloadProg", { guid: "logs-download", current: -1 });
					if (progressTimeout) $timeout.cancel(progressTimeout);
				});
		}

		function backToSearch() {
			vm.searchResult = false;
			$(".copyAllTarget")[0].value = "";
		}

		function copyLinkNotification() {
			successHandling.report("COPIED_TO_CLIPBOARD");
		}
		function jumpTo() {
			if (!textArea || textArea.length === 0) {
				textArea = $(".copyAllTarget");
			}
			if (textArea[0]) {
				var scrollTo = vm.atBottom ? 0 : textArea[0].scrollHeight;
				textArea.scrollTop(scrollTo);
				vm.atBottom = !vm.atBottom;
			}			
		}

	}

})();
