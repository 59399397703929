// Extended by email-popout.controller.js and email-section.controller.js

(function () {
    'use strict';
    angular
        .module('smartermail')
        .controller('emailActions', emailActionsController);

    function emailActionsController($rootScope, $scope, $http, $timeout, $mdDialog, $filter, $translate, $localStorage, $log, $state, $q,
        coreData, coreDataListener, coreDataMail, coreDataSettings, messageData, authStorage, errorHandling, emailValidationService,
        successHandling, userDataService, errorMessageService, popupService, restrictedSenders, toaster, apiCategories, emailFunctions) {
        var vm = this;
        var progressModal;
        var blockDoubleClick = 0;

        $scope.messageData = messageData;

        // FUNCTIONS 
        $scope.getSelectedUids = getSelectedUids;
        $scope.getSelectedItems = getSelectedItems;
        $scope.moveEmails = moveEmails;
        $scope.onDownloadRaw = onDownloadRaw;
        $scope.moveClicked = moveClicked;
        $scope.toggleFlag = toggleFlag;
        $scope.markMessageFlagDropdown = markMessageFlagDropdown;
        $scope.markMessageDropdown = markMessageDropdown;
        $scope.markMessageCategories = markMessageCategories;
        $scope.markMessagesFlag = markMessagesFlag;
        $scope.markMessage = markMessage;
        $scope.viewAsClicked = viewAsClicked;
        $scope.undeleteClicked = undeleteClicked;
        $scope.deleteClickedDropdown = deleteClickedDropdown;
        $scope.deleteClicked = deleteClicked;
        $scope.blockSenderDropdown = blockSenderDropdown;
        $scope.blockSender = blockSender;
        $scope.unblockSender = unblockSender;
        $scope.untrustSender = untrustSender;
        $scope.trustSender = trustSender;
        $scope.createFilter = createFilter;
        $scope.addToFilter = addToFilter;
        $scope.addTask = addTask;
        $scope.inviteToMeeting = inviteToMeeting;
        $scope.openManageCategoriesModal = openManageCategoriesModal;
        $scope.getCategoryStackHtml = getCategoryStackHtml;
        $scope.spamClicked = spamClicked;

        init();

        /////////////////////////////////////

        function init() {
            coreDataListener.start();
            $scope.$on("categoriesUpdated", reloadCategories);
            $scope.$on('signalR.mailHub.client.mailAction', onSignalrMailAction);
            $rootScope.$on('mail.refreshEmailTask', reloadForTask);
            $scope.$on('startEditTask', onStartEditTask);
        }

        function getSelectedUids() {
            var uids = ($scope.mailPanelGrid && $scope.mailPanelGrid.getSelectedIds()) || [messageData.navigationPacket.uid];
            for (var i = 0; i < uids.length; ++i) {
                uids[i] = Number(uids[i]);
            }
            return uids;
        }

        function getSelectedItems() {
            return $scope.mailPanelGrid.getSelectedItems();
        }

        function reloadCategories() {
            messageData.reloadCategories();
            if ($scope.mailPanelGrid)
                $scope.mailPanelGrid.repaint(true);
        }

        async function moveEmails(ids, fromFolder, fromFolderOwner, toFolder, toFolderOwner) {
            if (fromFolder == toFolder && fromFolderOwner == toFolderOwner) return;

            $rootScope.spinner.show();
            try {
                var parameters = {
                    'UID': ids,
                    'folder': fromFolder,
                    'ownerEmailAddress': fromFolderOwner,
                    'destinationFolder': toFolder,
                    'destinationOwnerEmailAddress': toFolderOwner
                };
                const response = await $http.post("~/api/v1/mail/move-messages", parameters);
                if ($scope.isPopout) {
                    $scope.folderPath = parameters.destinationFolder;
                    $scope.owner = parameters.destinationOwnerEmailAddress;
                } else if (!response.data.actionGuid) {
                    doEndMoveEmails(false, ids, parameters);
                }
            } catch (err) {
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
                if ($scope.isPopout) {
                    window.close();
                }
            }
        }

        function doEndMoveEmails(resetList, ids, parameters) {
            coreDataMail.resyncFolderUnreadCount(parameters.folder, parameters.ownerEmailAddress);
            coreDataMail.resyncFolderUnreadCount(parameters.destinationFolder, parameters.destinationOwnerEmailAddress);
            $scope.selectMode = false;
            $scope.mailPanelGrid.setSelectMode($scope.selectMode);
            $timeout(function () {
                if (resetList) {
                    //This is kind of weird and bad but basically the list will reload before it actually expunges the messages and it will show
                    // marked as deleted items that aren't actually there anymore.
                    $rootScope.spinner.show();
                    $timeout(function () {
                        $scope.mailPanelGrid.reset({ keepSelection: false });
                        $localStorage.ignoreMailSignal = undefined;
                        $rootScope.spinner.hide();
                    }, 1000);
                } else {
                    $localStorage.ignoreMailSignal = undefined;
                    $scope.mailPanelGrid.reset({}, function (panelRef, index) {
                        setTimeout(function () {
                            panelRef.focusedIndex = index;
                            panelRef.focusItem();

                            panelRef.selectItemByIndex(index, {});
                        }, 1);
                    });
                }
            });
            $scope.inActionProgess = false;

            var folderKey = parameters.destinationOwnerEmailAddress + "|" + parameters.destinationFolder;
            var folder = $scope.tree.map[folderKey];
            var folderName;
            if (folder) {
                folderName = $translate.instant(folder.label);
            } else {
                folder = $scope.shortTree.map[folderKey];
                if (folder) {
                    folderName = $translate.instant(folder.label);
                }
            }
            if (!folderName) {
                var folderParts = parameters.destinationFolder.split("/");
                folderName = folderParts[folderParts.length - 1];
                folderName = folderName.charAt(0).toUpperCase() + folderName.slice(1);
            }
            successHandling.report('MAIL_MESSAGES_MOVED', { count: ids.length, folder: folderName });
        }

        function onSignalrMailAction(event, data) {
            var percentProgress = 0;
            if (data.totalCount == -1) {
                if (data.current !== data.total && data.current !== -1) {
                    percentProgress = Math.floor(data.current / data.total * 100);
                } else if (data.current === data.total || data.current === -1) {
                    percentProgress = 100;
                }
            } else {
                var totalProcessed = data.current + data.processed;
                if (totalProcessed !== data.totalCount && data.current !== -1) {
                    percentProgress = Math.floor((totalProcessed / data.totalCount) * 100);
                } else if (data.current === -1 && data.asyncFolderCurrent == data.asyncFolderTotal) {
                    percentProgress = 100;
                }
            }

            console.debug("SignalrMailAction PROGRESS: " + percentProgress);
            if ($scope.inActionProgess && progressModal && percentProgress >= 100) {
                $mdDialog.hide();
                return;
            }

            if (!$scope.inActionProgess) {
                if ($rootScope.spinner.isShown) {
                    $rootScope.spinner.hide();
                    $timeout($rootScope.spinner.hide);
                }
                $scope.inActionProgess = true;
                $localStorage.ignoreMailSignal = true;
                var actTranslate = '';
                switch (data.action) {
                    case "MOVING":
                    case 'move':
                        actTranslate = $translate.instant('MOVING');
                        break;
                    case "DELETING":
                    case 'delete':
                        actTranslate = $translate.instant('DELETING');
                        break;
                    case "MARKING":
                    case 'mark':
                        actTranslate = $translate.instant('MARKING');
                        break;
                    default:
                        actTranslate = $translate.instant('ACTION');
                        break;
                }

                if (data.error && data.error.length > 0) {
                    toaster.pop("error", decodeHtml($translate.instant(data.error)));
                }

                progressModal = $mdDialog.stProgressBar()
                    .title($translate.instant('PROGRESS'))
                    .barText(actTranslate)
                    .showCancel(false)
                    .enableButtons(false)
                    .percentProgress(percentProgress)
                    .autoClose(true)
                    .guid(data.guid)
                    .progressListener('signalR.mailHub.client.mailAction');
                $mdDialog.show(progressModal).then(function () {
                    // cancel
                    doEndGenericAction();
                }, function () {
                    // close
                    doEndGenericAction();
                });
            }
        }

        function decodeHtml(html) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        }

        function doEndGenericAction() {
            $localStorage.ignoreMailSignal = undefined;
            $scope.inActionProgess = false;
            //see comment on doEndMoveEmails
            $rootScope.spinner.show();
            $timeout(function () {
                $scope.mailPanelGrid.reset({ keepSelection: false });
                $rootScope.spinner.hide();
            }, 1000);
        }

        async function onDownloadRaw(uid) {
            try {
                await emailFunctions.downloadEmlFile($scope.getActiveFolderOwner(), $scope.getActiveFolder(), uid || $scope.getSelectedUids());
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function spamClicked(markingAsSpam, uids) {
            if (uids == undefined) {
                uids = $scope.getSelectedUids();
            } else if (!Array.isArray(uids))
                uids = [uids];

            try {
                if (markingAsSpam) {
                    await emailFunctions.markSpam($scope.getActiveFolderOwner(), $scope.getActiveFolder(), uids);
                } else {
                    await emailFunctions.markNotSpam($scope.getActiveFolderOwner(), $scope.getActiveFolder(), uids);
                }
                if ($scope.isPopout) window.close();
                doEndDeleteMessages(false, uids);
                successHandling.report('MAIL_MESSAGES_MOVED', { count: uids.length, folder: markingAsSpam ? $translate.instant('JUNK_EMAIL') : $translate.instant('INBOX') });
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function moveClicked(uid) {
            if (uid == undefined) {
                uid = $scope.getSelectedUids();
            } else {
                if (!Array.isArray(uid)) { uid = [uid]; }
            }

            if (uid.length === 0) {
                errorHandling.report($translate.instant("MUST_SELECT"));
                return;
            }

            try {
                const success = await coreDataMail.getFolderList();
                const sortedFolders = success.sort(function (a, b) {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                });

                try {
                    const response = await $mdDialog.show({
                        locals: {
                            folders: sortedFolders,
                            folder: $scope.getActiveFolder(),
                            owner: $scope.getActiveFolderOwner()
                        },
                        controller: "moveEmailController",
                        templateUrl: 'app/email/modals/move-email.dlg.html',
                        //targetEvent: ev,
                        clickOutsideToClose: false
                    });
                    $scope.moveEmails(uid,
                        $scope.getActiveFolder(),
                        $scope.getActiveFolderOwner(),
                        response.folder,
                        response.owner);
                } catch (ignored) {
                    // dialog throws exception when it's closed normally
                }
            } catch (err) {
                errorHandling.report(err);
            }
        }

        function toggleFlag(uid, isFlagged) {
            if (isFlagged) {
                $scope.markMessagesFlag('Clear', uid);
            } else {
                // allow flagging of draft messages; setting a flag on a draft message will create a recipient flag
                $scope.markMessagesFlag('SetBasic', uid);
            }
        }

        function markMessageFlagDropdown(params) {
            var confirmText, toastText, toastData;
            var itemCount = params.uid.length;
            switch (params.type) {
                case "Clear":
                    confirmText = $filter('translate')('CONFIRMATIONS_UNFLAG_ITEMS', { items: itemCount });
                    toastText = 'MAIL_MESSAGES_UNFLAGGED';
                    toastData = { count: itemCount };
                    break;
                case "SetBasic":
                    confirmText = $filter('translate')('CONFIRMATIONS_FLAG_ITEMS', { items: itemCount });
                    toastText = 'MAIL_MESSAGES_FLAGGED';
                    toastData = { count: itemCount };
                    break;
            }

            // Check if uid is undefined (multiple messages selected)
            if (params.uid != undefined && params.uid.length > 1) {
                // Show confirmation dialog
                var confirm = $mdDialog.confirmDeletion({
                    textContent: $translate.instant(confirmText),
                    ok: $translate.instant('CONFIRM'),
                    noWarn: true
                });
                $mdDialog.show(confirm).then(function () {
                    $scope.markMessagesFlag(params.type, params.uid);
                    successHandling.report(toastText, toastData);
                }, function () { });
            } else {
                $scope.markMessagesFlag(params.type, params.uid);
                successHandling.report(toastText, toastData);
            }
        }

        function markMessageDropdown(params) {
            var confirmText, toastText, toastData;
            var itemCount = params.uid.length;
            switch (params.type) {
                case "read":
                    confirmText = $filter('translate')('CONFIRMATIONS_MARK_ITEMS_READ', { items: itemCount });
                    toastText = 'MAIL_MESSAGES_MARKED_READ';
                    toastData = { count: itemCount };
                    break;
                case "unread":
                    confirmText = $filter('translate')('CONFIRMATIONS_MARK_ITEMS_UNREAD', { items: itemCount });
                    toastText = 'MAIL_MESSAGES_MARKED_UNREAD';
                    toastData = { count: itemCount };
                    break;
            }

            // Check if uid is undefined (multiple messages selected)
            if (params.uid != undefined && params.uid.length > 1) {
                // Show confirmation dialog
                var confirm = $mdDialog.confirmDeletion({
                    textContent: $translate.instant(confirmText),
                    ok: $translate.instant('CONFIRM'),
                    noWarn: true
                });
                $mdDialog.show(confirm).then(function () {
                    $scope.markMessage(params.type, params.uid, params.preventInvalidation);
                    successHandling.report(toastText, toastData);
                }, function () { });
            } else {
                $scope.markMessage(params.type, params.uid, params.preventInvalidation);
                successHandling.report(toastText, toastData);
            }
        }

        // markCategoryParams { clearCategories: true | false, removeCategories : true | false, categories : [] } 
        function markMessageCategories(markCategoryParams, uid) {
            var defer = $q.defer();
            var promise = defer.promise;
            if (uid === undefined) {
                uid = $scope.getSelectedUids();
            } else {
                if (!Array.isArray(uid)) {
                    uid = [uid];
                }
            }
            var messageIsLoaded = false;
            for (var i = 0; i < uid.length; ++i) {
                if (uid[i] == messageData.message.uid) {
                    messageIsLoaded = true;
                    break;
                }
            }
            if (uid.length === 0) {
                errorHandling.report($translate.instant("MUST_SELECT"));
                return promise;
            }

            if (!messageData.prop.fullAccess) {
                errorHandling.report($translate.instant("READ_ONLY_GENERIC"));
                return promise;
            }

            var folder = $scope.getActiveFolder();
            var owner = $scope.getActiveFolderOwner();
            var parameters = {
                'UID': uid,
                'folder': folder,
                'OwnerEmailAddress': owner
            };

            if (markCategoryParams.clearCategories) {
                parameters.clearCategories = markCategoryParams.clearCategories;
                if (messageIsLoaded) {
                    messageData.message.categories = [];
                }
                if (!$scope.isPopout) {
                    for (let i = 0; i < uid.length; ++i) {
                        let v = $scope.mailPanelGrid.getItemById(uid[i]);
                        if (v) {
                            v.categories = [];
                        }
                    }
                    const selectedElements = $(uid.length === 1 ? `.panel-item[data-id='${uid[0]}'] .mailGridItem .category-stack` : ".panel-item.selected .mailGridItem  .category-stack");
                    if (selectedElements) selectedElements.innerHTML = "";

                }
            }
            if (markCategoryParams.removeCategories)
                parameters.removeCategories = markCategoryParams.removeCategories;

            if (markCategoryParams.categories) {

                parameters.categories = markCategoryParams.categories;

                if (markCategoryParams.removeCategories) {
                    const removeCategories = function (result, cat) {
                        if (!parameters.categories.includes(cat))
                            result.push(cat);
                        return result;
                    }
                    if (messageIsLoaded) {
                        messageData.message.categories =
                            messageData.message.categories.reduce(removeCategories, []);
                    }
                    if (!$scope.isPopout) {
                        for (let i = 0; i < uid.length; ++i) {
                            let v = $scope.mailPanelGrid.getItemById(uid[i]);
                            if (v) {
                                v.categories = v.categories.reduce(removeCategories, []);
                                const categoryStack = $(`.panel-item[data-id='${uid[i]}'] .mailGridItem .category-stack`);
                                if (categoryStack) categoryStack.innerHTML = $scope.getCategoryStackHtml(v.categories);
                            }
                        }
                    }
                } else {
                    const addCategories = function (categories) {
                        if (!categories) categories = [];
                        parameters.categories.forEach(addCat => {
                            if (!categories.includes(addCat)) {
                                categories.push(addCat);
                            }
                        });
                        return categories;
                    }
                    if (messageIsLoaded) {
                        messageData.message.categories = addCategories(messageData.message.categories);
                    }
                    if (!$scope.isPopout && uid.length < 75) {
                        for (let i = 0; i < uid.length; ++i) {
                            let v = $scope.mailPanelGrid.getItemById(uid[i]);
                            if (v) {
                                v.categories = addCategories(v.categories);
                                const categoryStack = $(`.panel-item[data-id='${uid[i]}'] .mailGridItem .category-stack`);
                                if (categoryStack) categoryStack.innerHTML = $scope.getCategoryStackHtml(v.categories);
                            }
                        }
                    }
                }
            }

            $http
                .post("~/api/v1/mail/messages-category-patch", parameters)
                .then(function () {
                    if (messageIsLoaded) {
                        messageData.reloadCategories();
                    }
                    $scope.ignoreSignalUids = _.union($scope.ignoreSignalUids, uid);
                    if ($scope.mailPanelGrid && typeof $scope.mailPanelGrid.reset === 'function')
                        $scope.mailPanelGrid.reset({ keepSelection: true });
                    defer.resolve();
                }, function (response) {
                    if (response && response.data && response.data.message && response.data.message === "Invalid Access")
                        errorHandling.report($translate.instant("READ_ONLY_GENERIC"));
                    else
                        errorHandling.report(response);

                    // If we get an error revert setting the category
                    const removeCategories = function (result, cat) {
                        if (!parameters.categories.includes(cat))
                            result.push(cat);
                        return result;
                    }
                    messageData.message.categories = messageData.message.categories.reduce(removeCategories, []);
                    $scope.mailPanelGrid.reset();


                    defer.reject;
                })
                .finally(function () {
                    if (typeof $scope.setSelectMode === 'function')
                        $scope.setSelectMode(false, false);
                });
            return promise;

        }

        function markMessagesFlag(flagAction, uid) {
            // valid flagAction are Clear, SetBasic
            //	Complete and SetFollowup actions not yet supported 
            var defer = $q.defer();
            var promise = defer.promise;
            var messageIsLoaded = false;
            if (uid == undefined) {
                uid = $scope.getSelectedUids();
            } else {
                if (!Array.isArray(uid)) {
                    uid = [uid];
                }
            }
            for (var i = 0; i < uid.length; ++i) {
                if (uid[i] === messageData.message.uid) {
                    messageIsLoaded = true;
                    break;
                }
            }
            if (uid.length === 0) {
                errorHandling.report($translate.instant("MUST_SELECT"));
                return promise;
            }
            // if there's only one, refine the expression otherwise they must be in selection set
            let itemsExpression = uid.length === 1 ? `.panel-item[data-id='${uid[0]}'] .mailGridItem` : ".panel-item.selected .mailGridItem";
            const folder = $scope.getActiveFolder();
            const owner = $scope.getActiveFolderOwner();
            const parameters = {
                'UID': uid,
                'folder': folder,
                'OwnerEmailAddress': owner,
                'flagAction': { type: flagAction }
            };

            if (flagAction === "Clear") {
                if (messageIsLoaded) {
                    messageData.message.isFlagged = false;
                }


                if (!$scope.isPopout) {
                    itemsExpression += ".markedAsFlaggedBg";
                    const selectedElements = $(itemsExpression);
                    selectedElements.each(function () {
                        const mailGrid = $(this);
                        const dataId = mailGrid.parent().attr("data-id");
                        mailGrid.removeClass("markedAsFlaggedBg");
                        mailGrid.find(".toolsicon-flag").each(function () {
                            $(this).addClass("messageNotFlagged");
                            this.onclick = function () { window.toggleFlag(dataId, false); };
                        });
                    });
                    if (uid.length > 1) {
                        const flaggedGridItems = $scope.mailPanelGrid.getSelectedItems().filter(x => x.isFlagged);
                        flaggedGridItems.forEach(function (item) {
                            item.isFlagged = false;
                        });
                    } else {
                        const item = $scope.mailPanelGrid.getItemById(uid[0]);
                        if (item && item.isFlagged) item.isFlagged = false;
                    }

                }

            } else if (flagAction === "SetBasic") {
                if (messageIsLoaded) {
                    messageData.message.isFlagged = true;
                }
                if (!$scope.isPopout) {
                    const selectedElements = $(itemsExpression);
                    selectedElements.each(function () {
                        const mailGrid = $(this);
                        const dataId = mailGrid.parent().attr("data-id");
                        mailGrid.addClass("markedAsFlaggedBg");
                        mailGrid.find(".toolsicon-flag.messageNotFlagged").each(function () {
                            $(this).removeClass("messageNotFlagged");
                            this.onclick = function () { window.toggleFlag(dataId, true); };
                        });
                    });
                    if (uid.length > 1) {

                        const unflaggedGridItems = $scope.mailPanelGrid.getSelectedItems().filter(x => !x.isFlagged);
                        unflaggedGridItems.forEach(function (item) {
                            item.isFlagged = true;
                        });
                    } else {
                        const item = $scope.mailPanelGrid.getItemById(uid[0]);
                        if (item) item.isFlagged = true;
                    }
                }
            } else {
                // TODO add support for SetFollowup with dates and Complete
                return promise;
            }

            $http
                .post("~/api/v1/mail/messages-flag-patch", parameters)
                .then(function (response) {
                    $scope.ignoreSignalUids = _.union($scope.ignoreSignalUids, uid);
                    if (!response.data.actionGuid) {
                        doEndMarkMessages(false, false, flagAction);
                    }
                    defer.resolve();
                }, defer.reject)
                .finally(function () {
                    if (typeof $scope.setSelectMode === 'function')
                        $scope.setSelectMode(false, false);
                });
            return promise;
        }

        function markMessage(type, uid) {

            var defer = $q.defer();
            var promise = defer.promise;
            var messageIsLoaded = false;
            if (uid == undefined) {
                uid = $scope.getSelectedUids();
            } else {
                if (!Array.isArray(uid)) {
                    uid = [uid];
                }
            }

            for (var i = 0; i < uid.length; ++i) {
                if (uid[i] == messageData.message.uid) {
                    messageIsLoaded = true;
                    break;
                }
            }

            if (uid.length === 0) {
                errorHandling.report($translate.instant("MUST_SELECT"));
                return;
            }
            var folder = $scope.getActiveFolder();
            var owner = $scope.getActiveFolderOwner();
            var parameters = {
                'UID': uid,
                'folder': folder,
                'OwnerEmailAddress': owner
            };
            var repaintGrid = false;
            switch (type) {
                case 'read':
                    parameters.markRead = true;
                    if (messageIsLoaded) {
                        messageData.message.isSeen = true;
                    }
                    if (!$scope.isPopout && uid.length < 75) {
                        for (let i = 0; i < uid.length; ++i) {
                            let v = $scope.mailPanelGrid.getItemById(uid[i]);
                            if (v) {
                                v.isSeen = true;
                            }
                            $('#mailGridItem-' + uid[i]).attr('read', "true");
                        }
                    }
                    break;
                case 'unread':
                    parameters.markRead = false;
                    if (messageIsLoaded) {
                        messageData.message.isSeen = false;
                    }
                    if (!$scope.isPopout && uid.length < 75) {
                        for (let i = 0; i < uid.length; ++i) {
                            let v = $scope.mailPanelGrid.getItemById(uid[i]);
                            if (v) {
                                v.isSeen = false;
                            }
                            $('#mailGridItem-' + uid[i]).attr('read', "false");
                        }
                    }
                    break;
                case 'undelete':
                    parameters.markDeleted = false;
                    if (messageIsLoaded) {
                        messageData.message.isDeleted = false;
                    }
                    if (!$scope.isPopout && uid.length < 75) {
                        for (let i = 0; i < uid.length; ++i) {
                            let v = $scope.mailPanelGrid.getItemById(uid[i]);
                            if (v) {
                                v.isDeleted = false;
                            }
                            $('#mailGridItem-' + uid[i]).removeClass("markedAsDeleted");
                        }
                    }
                    break;
                case 'bypassRemoteContent':
                    parameters.bypassRemoteContent = true;
                    if (messageIsLoaded) {
                        messageData.message.bypassRemoteContent = true;
                    }
                    repaintGrid = true;
                    break;
            }

            //$scope.inActionProgess = true;
            $http
                .post("~/api/v1/mail/messages-patch", parameters)
                .then(function (response) {
                    $scope.ignoreSignalUids = _.union($scope.ignoreSignalUids, uid);
                    if (!response.data.actionGuid) {
                        doEndMarkMessages(repaintGrid, false, type);
                    }
                    defer.resolve();
                }, defer.reject)
                .finally(function () {
                    if (typeof $scope.setSelectMode === 'function')
                        $scope.setSelectMode(false, repaintGrid);
                });
            return promise;
        }

        function doEndMarkMessages(repaintGrid, reset, type) {
            if (!$scope.isPopout) {
                if (($scope.mailList.filterType === 0 && $scope.mailList.filterValue && type === 'unread') || //Filter is read
                    ($scope.mailList.filterType === 4 && type === 'unflagged') || //Filter is flagged
                    reset) {
                    $scope.mailPanelGrid.reset({ keepSelection: true });
                } else if (repaintGrid) {
                    $scope.mailPanelGrid.repaint(true);
                }
                coreDataMail.resyncFolderUnreadCount($scope.getActiveFolder(), $scope.getActiveFolderOwner());
                messageData.refreshSelection($scope.getSelectedItems());
            }
            $scope.inActionProgess = false;
        }

        async function viewAsClicked(mode) {
            if (messageData.markAsReadQueue.includes(messageData.message.uid)) {
                messageData.markAsReadQueue.splice(messageData.markAsReadQueue.indexOf(messageData.message.uid));
                if (!messageData.message.isSeen ? true : false)
                    $scope.markMessage("read", messageData.message.uid);
            }

            try {
                if (mode === 'raw' && !messageData.message.raw) {
                    // If they're requesting raw and we don't have it, load it
                    messageData.message.raw = await emailFunctions.loadRawContent(messageData.message.ownerEmailAddress || '', messageData.message.folder, messageData.message.uid, false);
                }

                messageData.mode = mode;
                $scope.$broadcast("mail.refreshView");
            } catch (err) {
                errorHandling.report(err);
            }
        }

        function undeleteClicked(uid) {
            if (uid == undefined) {
                uid = $scope.getSelectedUids();
            } else {
                if (!Array.isArray(uid))
                    uid = [uid];
            }

            if (uid.length === 0) {
                errorHandling.report($translate.instant("MUST_SELECT"));
                return;
            }

            if (uid.length > 1) {
                // Show confirmation dialog
                var confirm = $mdDialog.confirmDeletion({
                    textContent: $translate.instant('CONFIRMATIONS_DELETE_MESSAGES', { count: uid.length, delete: false }),
                    ok: $translate.instant('UNDELETE'),
                    noWarn: true
                });
                $mdDialog.show(confirm).then(undeleteLogic, function () { });
            } else {
                undeleteLogic();
            }

            function undeleteLogic() {
                var needsToMove = $scope.getActiveFolder().toLowerCase() == "deleted items";
                $scope.markMessage('undelete', uid, needsToMove);
                if (needsToMove) {
                    $scope.moveEmails(uid, $scope.getActiveFolder(), $scope.getActiveFolderOwner(), "inbox", $scope.getActiveFolderOwner());
                } else {
                    successHandling.report('MAIL_MESSAGES_UNDELETED', { count: uid.length });
                }
                if ($scope.isDeleted) {
                    $scope.isDeleted = false;
                }
            }
        }

        function deleteClickedDropdown(params) {
            $scope.deleteClicked(params.uid, false, null, null, false);
        }

        function deleteClicked(uid, isSpoolMessage, fileName, spoolName, skipConfirmation) {
            if (uid == undefined) {
                uid = $scope.getSelectedUids();
            } else if (!Array.isArray(uid))
                uid = [uid];

            if (!skipConfirmation && uid.length > 1) {
                // Show confirmation dialog
                var confirm = $mdDialog.confirmDeletion({
                    textContent: $translate.instant("CONFIRMATIONS_DELETE_MESSAGES", { count: $scope.isPopout ? 1 : uid.length, delete: true }),
                    ok: $translate.instant("DELETE")
                });
                $mdDialog.show(confirm).then(function () {
                    deleteLogic();
                }, function () { });
            } else {
                deleteLogic();
            }

            function deleteLogic() {
                if (!$rootScope.spinner.isShown) {
                    $rootScope.spinner.show();
                }
                var deltaTime = Date.now() - blockDoubleClick;
                if (deltaTime < 1000)
                    return false;

                if (uid.length === 0) {
                    errorHandling.report($translate.instant("MUST_SELECT"));
                    return;
                }

                var parameters = {
                    'UID': uid,
                    'folder': $scope.getActiveFolder(),
                    'ownerEmailAddress': $scope.getActiveFolderOwner(),
                    'moveToDeleted': true
                };
                $scope.isMarkedAsDeleted = true;
                if ($scope.tree && $scope.tree.selectedBranchData) {
                    if (coreDataSettings.userSettings.deleteOption !== 2 && $scope.tree.selectedBranchData.deleteBehavior !== 2) {
                        $scope.isMarkedAsDeleted = false;
                    }
                } else {
                    if (coreDataSettings.userSettings.deleteOption !== 2) {
                        $scope.isMarkedAsDeleted = false;
                    }
                }
                blockDoubleClick = Date.now();
                //$scope.inActionProgess = true;
                if (!$scope.isPopout) {
                    $localStorage.ignoreMailSignal = $scope.getActiveFolder();
                }
                $http.post("~/api/v1/mail/delete-messages", parameters)
                    .then(function (response) {
                        $rootScope.spinner.hide();
                        if (!response.data.actionGuid) {
                            blockDoubleClick = 0;
                            if (!$scope.isPopout) {
                                doEndDeleteMessages(false, uid);
                                successHandling.report('MAIL_MESSAGES_DELETED', { count: uid.length });
                            } else {
                                window.close();
                            }
                        }
                    }, function (response) {
                        blockDoubleClick = 0;
                        if (errorMessageService.isServiceDown(response)) {
                            $rootScope.spinner.hide();
                            errorMessageService.showErrorMessage(response);
                            $localStorage.ignoreMailSignal = undefined;
                            $scope.inActionProgess = false;
                        } else {
                            doEndDeleteMessages(false, []);
                            successHandling.report('MAIL_MESSAGES_DELETED', { count: 0 });
                            errorHandling.reportAndHideSpinner(response.data ? response.data.message : response);
                        }
                    });
            }
        }

        function doEndDeleteMessages(resetList, uids) {
            // Keep in mind deleting can remove marked-as-deleted items that may not even be loaded yet - Mike
            coreDataMail.resyncFolderUnreadCount($scope.getActiveFolder(), $scope.getActiveFolderOwner());
            if (resetList) {
                $scope.mailPanelGrid.reset({ keepSelection: false });
            } else {
                $scope.mailPanelGrid.reset({}, function (panelRef, index) {
                    setTimeout(function () {
                        panelRef.focusedIndex = index;
                        panelRef.focusItem();

                        panelRef.selectItemByIndex(index, {});
                    }, 1);
                });
            }
            $scope.ignoreSignalUids = _.union($scope.ignoreSignalUids.concat, uids);
            $scope.selectMode = false;
            $scope.mailPanelGrid.setSelectMode($scope.selectMode);
            $localStorage.ignoreMailSignal = undefined;
            if (vm.stopIgnoreMoveSignal) {
                $timeout.cancel(vm.stopIgnoreMoveSignal);
            }
            $scope.inActionProgess = false;
        }

        function getEmailUidDictionary(uid, includeReturnPath) {
            var result = {
                addresses: {},
                fromAddressCount: 0,
                disallowedSelf: false
            };

            var fromCount = 0;
			var fromAddresses = {};
			var returnPaths = {};

            for (var i = 0; i < uid.length; ++i) {
                var selectedMessage = undefined;
                if ($scope.mailPanelGrid) {
                    selectedMessage = $scope.mailPanelGrid.getItemById(uid[i]);
                }
                if (selectedMessage == undefined || !selectedMessage.fromAddress) {
                    selectedMessage = messageData.message;
                }

                if (selectedMessage.isDraft || false)
                    continue;

                var fromEmail = selectedMessage.fromAddress.email ? selectedMessage.fromAddress.email.toLowerCase() : selectedMessage.fromAddress.toLowerCase();

                if (fromEmail.indexOf(coreData.user.username) > -1) {
                    fromEmail = restrictedSenders.convertEmailFromDomainAlias(fromEmail).email;
                }

				var isValid = emailValidationService.isValidEmail(fromEmail);

				if (fromEmail !== coreData.user.emailAddress.toLowerCase()) {
					if (isValid && !fromAddresses[fromEmail]) {
						fromAddresses[fromEmail] = [uid[i]];
						fromCount++;
					}
					else if (fromAddresses[fromEmail] && !fromAddresses[fromEmail].includes(uid[i])) {
						fromAddresses[fromEmail].push(uid[i])
					}
				}
				else
					result.disallowedSelf = true;

				if (includeReturnPath) {
					let returnPath = selectedMessage.returnPath.toLowerCase();
                    if (returnPath !== coreData.user.emailAddress.toLowerCase() && emailValidationService.isValidEmail(returnPath)) {
						if (!returnPaths[returnPath])
							returnPaths[returnPath] = [uid[i]];
						else if (!returnPaths[returnPath].includes(uid[i]))
                            returnPaths[returnPath].push(uid[i]);
					}
				}
			}

			result.addresses = fromAddresses;
			result.fromAddressCount = fromCount;
			for (let email in returnPaths) {
				if (!returnPaths.hasOwnProperty(email))
					continue;

				if (!result.addresses[email]) {
					result.addresses[email] = returnPaths[email];
				}
				else {
					for (let i = 0; i < returnPaths[email].length; i++) {
						let msgUid = returnPaths[email][i];
						if (!result.addresses[email].includes(msgUid))
							result.addresses[email].push(msgUid);
					}
				}
			}

            return result;
        }

        function blockSenderDropdown(params) {
            $scope.blockSender(params.uid, params.ev);
        }

        function blockSender(uid, ev) {
            if (uid && uid.length === 0) {
                errorHandling.report("MUST_SELECT");
                return;
            }
            if (!uid) uid = $scope.getSelectedUids();
            if (!Array.isArray(uid)) uid = [uid];

            var result = getEmailUidDictionary(uid, true);
            var addresses = result.addresses;
            var addressCount = result.fromAddressCount;
            
            if (addressCount > 1) {
                var translation = "CONFIRMATIONS_BLOCK_SENDERS_" + coreDataSettings.userSettings.blockedSenderAction;
                translation =
                    $filter("translate")("CONFIRMATIONS_BLOCK_SENDERS_0", { count: addressCount }) +
                    (translation === "CONFIRMATIONS_BLOCK_SENDERS_0" ? "" : (" " + $filter("translate")(translation)));
                var confirm = $mdDialog.confirmDeletion()
                    .textContent(translation)
                    .ok($filter('translate')('BLOCK'))
                    .title($filter('translate')('BLOCK_SENDER'))
                    .targetEvent(ev);
                $mdDialog.show(confirm).then(function () { doBlockSenders(addresses, result.disallowedSelf); });
            } else if (addressCount === 1) {
                doBlockSenders(addresses, result.disallowedSelf);
            } else if (result.disallowedSelf) {
                errorHandling.warn('MAIL_NO_BLOCK_SELF');
            } else {
                errorHandling.warn('MAIL_NO_BLOCK_SENDERS');
            }
        }

        async function doBlockSenders(addresses, showGalWarning) {
            try {
                const wasBlocked = await emailFunctions.blockSender($scope.getActiveFolderOwner(), $scope.getActiveFolder(), addresses, showGalWarning, true, true);
                if (wasBlocked) {
                    if ($scope.isPopout) window.close();
                }
            } catch (err) {
                // If blocking them fails, we can silently ignore to not confuse the user
            } finally {
                if (typeof $scope.setSelectMode === 'function')
                    $scope.setSelectMode(false, true);
            }
        }

        function unblockSender(uid) {
            if (uid && uid.length === 0) {
                errorHandling.report("MUST_SELECT");
                return;
            }
            if (!uid) uid = $scope.getSelectedUids();
            if (!Array.isArray(uid)) uid = [uid];

            var result = getEmailUidDictionary(uid, true);
            var addresses = result.addresses;
            var addressCount = result.fromAddressCount;

            if (addressCount > 1) {
                var confirm = $mdDialog.confirmDeletion()
                    .textContent($filter('translate')('CONFIRMATIONS_UNBLOCK_SENDERS', { count: addressCount }))
                    .ok($filter('translate')('UNBLOCK'))
                    .title($filter('translate')('UNBLOCK_SENDER'));
                $mdDialog.show(confirm).then(function () { doUnblockSenders(addresses); });
            } else if (addressCount === 1) {
                doUnblockSenders(addresses);
            } else if (result.disallowedSelf) {
                errorHandling.warn('MAIL_NO_BLOCK_SELF');
            } else {
                errorHandling.warn('MAIL_NO_UNBLOCK_SENDERS');
            }
        }

        async function doUnblockSenders(addresses) {
            try {
                await emailFunctions.unblockSender($scope.getActiveFolderOwner(), $scope.getActiveFolder(), addresses, true);
            } catch (err) {
                // If unblocking them fails, we can silently ignore to not confuse the user
            } finally {
                if (typeof $scope.setSelectMode === 'function')
                    $scope.setSelectMode(false, true);
            }
        }

        function untrustSender(uid) {
            if (uid && uid.length === 0) {
                errorHandling.report("MUST_SELECT");
                return;
            }
            if (!uid) uid = $scope.getSelectedUids();
            if (!Array.isArray(uid)) uid = [uid];

            var result = getEmailUidDictionary(uid);

            if (result.fromAddressCount > 1) {
                var confirm = $mdDialog.confirmDeletion()
                    .textContent($filter('translate')('CONFIRMATIONS_UNTRUST_SENDERS', { count: result.fromAddressCount }))
                    .ok($filter('translate')('UNTRUST'))
                    .title($filter('translate')('UNTRUST_SENDERS'));
                $mdDialog.show(confirm).then(function () { doUntrustSenders(uid); });
            } else if (result.fromAddressCount === 1) {
                doUntrustSenders(uid);
            } else {
                errorHandling.warn('MAIL_NO_UNTRUST_SENDERS');
            }
        }

        async function doUntrustSenders(uids) {
            try {
                await emailFunctions.untrustSender($scope.getActiveFolderOwner(), $scope.getActiveFolder(), uids, false);
            } catch (err) {
                // If untrusting them fails, we can silently ignore to not confuse the user
            } finally {
                $rootScope.$broadcast("loadMessage");
                if (typeof $scope.setSelectMode === 'function')
                    $scope.setSelectMode(false, true);
            }
        }

        function trustSender(uid) {
            if (uid && uid.length === 0) {
                errorHandling.report("MUST_SELECT");
                return;
            }
            if (!uid) uid = $scope.getSelectedUids();
            if (!Array.isArray(uid)) uid = [uid];

            var result = getEmailUidDictionary(uid);

            if (result.fromAddressCount > 1) {
                var confirm = $mdDialog.confirmDeletion()
                    .textContent($filter('translate')('CONFIRMATIONS_TRUST_SENDERS', { count: result.fromAddressCount }))
                    .ok($filter('translate')('TRUST'))
                    .title($filter('translate')('TRUST_SENDERS'));
                $mdDialog.show(confirm).then(function () { doTrustSender(uid); });
            } else if (result.fromAddressCount === 1) {
                doTrustSender(uid);
            } else {
                errorHandling.warn('MAIL_NO_TRUST_SENDERS');
            }
        }

        async function doTrustSender(uids) {
            try {
                await emailFunctions.trustSender($scope.getActiveFolderOwner(), $scope.getActiveFolder(), uids, false);
            } catch (err) {
                // If trusting them fails, we can silently ignore to not confuse the user
            } finally {
                $rootScope.$broadcast("loadMessage");
                if (typeof $scope.setSelectMode === 'function')
                    $scope.setSelectMode(false, true);
            }
        }

        function createFilter(uid) {
            if (uid == undefined) {
                uid = $scope.getSelectedUids();
            } else {
                if (!Array.isArray(uid)) {
                    uid = [uid];
                }
            }

            if (uid.length === 0) {
                errorHandling.report($translate.instant("MUST_SELECT"));
                return;
            }

            if ($scope.isPopout) {
                let data = {
                    from: $scope.messageData.message.fromAddress.email || $scope.messageData.message.fromAddress.name,
                    to: $scope.messageData.message.ownerEmailAddress,
                    subject: $scope.messageData.message.subject
                };
                localStorage.setItem('newFilterData', JSON.stringify(data));
                $state.go("index.settings.user-content-filters.specific", { id: "new" });
            }
            else {
                let item = $scope.mailPanelGrid.getItemById(uid[0]);
                let data = {
                    from: item.from.email || item.from.name,
                    to: item.ownerEmailAddress,
                    subject: item.subject
                };
                localStorage.setItem('newFilterData', JSON.stringify(data));
                $state.go("index.settings.user-content-filters.specific", { id: "new" });
            }
        }

        function addToFilter(uid) {
            if (uid == undefined) {
                uid = $scope.getSelectedUids();
            } else {
                if (!Array.isArray(uid)) {
                    uid = [uid];
                }
            }

            if (uid.length === 0) {
                errorHandling.report($translate.instant("MUST_SELECT"));
                return;
            }

            var data = [];
            for (var i = 0; i < uid.length; i++) {
                data.push($scope.mailPanelGrid.getItemById(uid[i]));
            }

            $mdDialog.show({
                locals: { messages: data },
                controller: "addToFilterController",
                templateUrl: 'app/email/modals/add-to-filter.dlg.html',
                clickOutsideToClose: false
            }).then(function (result) {
                // Get selected content filter from modal
                var filter = result.filter;

                // Add uid data to filter's conditions
                for (var d = 0; d < data.length; d++) {
                    for (var f = 0; f < filter.filters.length; f++) {
                        if (filter.filters[f].fieldType != 1 && filter.filters[f].fieldType != 0 && filter.filters[f].fieldType != 6) {
                            continue;
                        }

                        var newArgument = data[d].from.email || data[d].from.name;
                        filter.filters[f].searchArguments = filter.filters[f].searchArguments || [];
                        // If it's a 'From specific domains' condition
                        if (filter.filters[f].fieldType == 1) {
                            newArgument = newArgument.split('@');
                            newArgument = newArgument[newArgument.length - 1];
                        }

                        if (filter.filters[f].searchArguments.indexOf(newArgument) == -1) {
                            filter.filters[f].searchArguments.push(newArgument);
                        }
                    }
                }

                // Save modified filter
                var params = JSON.stringify({ contentFilterGroups: [filter] });
                $http.post('~/api/v1/settings/content-filter-groups', params).then(function () {
                    successHandling.report('ADD_SENDER_TO_FILTER_TOAST', { filter: filter.title });
                }, errorHandling.report);
            }, function () { });
        }

        async function onStartEditTask(ev, taskInfo) {
            try {
                $rootScope.spinner.show();

                authStorage.getPopoutData(); //clear popout data just to make sure.
                var index = window.location.href.indexOf('/popout/email') > -1 ? window.location.href.indexOf('/popout/email') : window.location.href.indexOf('/email');
                const url = `${window.location.href.slice(0, index)}/popout/task/${taskInfo.id}/${taskInfo.sourceId}/${taskInfo.sourceOwner}`;
                window.open(url, taskInfo.id, "resizable=1, " + popupService.dimensions.task);

            } catch (err) {
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function addTask(activeEmailID) {
            if (activeEmailID == undefined)
                activeEmailID = $scope.activeEmailID;

            try {
                await emailFunctions.popupAddTaskModal(messageData.message);
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function inviteToMeeting() {
            if (messageData.message.isCalendarMessage) return;
            try {
                await emailFunctions.popupInviteToMeetingPopup(messageData.message);
            } catch (err) {
                errorHandling.report(err);
            }
        }
        function openManageCategoriesModal(ev) {
            $mdDialog.show({
                controller: "manageCategoriesDialogController",
                controllerAs: "manageCategoriesCtrl",
                templateUrl: "app/shared/modals/manage.categories.dlg.html",
                targetEvent: ev
            })
                .then(function () {
                    reloadCategories();
                    if ($scope.isPopout) {
                        $scope.$broadcast("loadMessage");
                    }
                })
                .finally(function () {
                    if (typeof $scope.setSelectMode === 'function')
                        $scope.setSelectMode(false, false);
                });
        }

        function getCategoryStackHtml(categories) {
            var html = '';

            if (categories && categories.length > 0) {
                var moreCount = 0;
                categories.forEach((catName, i) => {
                    if (i < 3) {
                        var cat = apiCategories.getCategoryByName(catName);
                        var colorIndex = -1;
                        if (cat) {
                            colorIndex = cat.colorIndex;
                        }
                        var rgbColor = apiCategories.getCategoryColor(colorIndex).rgb;
                        html += `<div class="category-tag">${window.getCategoryFlagHtml(rgbColor)}</div>`;
                    } else
                        moreCount++;
                });
                if (moreCount > 0)
                    html += `<div class="category-stack-item-more">+${moreCount}</div>`;
            }
            return html;
        }

        function reloadForTask() {
            $scope.$broadcast("unloadMessage", {});
            messageData.messageLoaded = false;
            var branchData = ($scope.tree && $scope.tree.selectedBranchData) || {
                path: $scope.folderPath,
                owner: $scope.owner
            };

            $scope.$broadcast("loadMessage", { uid: messageData.navigationPacket.uid, folder: branchData.path, owner: branchData.owner });
        }
    }

})();
