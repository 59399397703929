(function () {
	"use strict";

	angular
		.module('smartermail')
		.component('senderTrustInfo', {
			templateUrl: "app/email/message-view-components/sender-trust-info.component.html",
			controller: senderTrustInfoController,
			transclude: false,
			bindings: {
				messageData: '<',
			}
		});

	/* @ngInject */
	function senderTrustInfoController($rootScope, $scope, $state, $log, $http, $filter) {
		var vm = this;
		vm.showTrustMenu = false;
		vm.isForcedMode = false;
		vm.spamWeight = "None";

		// Functions
		vm.flipTrustFloater = flipTrustFloater;
		vm.forceTrustFloater = forceTrustFloater;

		activate();

		/////////////////

		vm.$onInit = function () {
		};

		vm.$onChanges = function (changesObj) {
			calculateSpamWeightString();
		};

		function activate() {
			
		}

		function flipTrustFloater(ev, newstate) {
			if (vm.isForcedMode)
				return;
			if (newstate) {
				let trustFloaters = document.getElementsByClassName("trust-floater");
				let trustFloaterIcon = ev.currentTarget;
				let headerGrid = document.getElementById("headergrid");
				let iconRect = headerGrid.getBoundingClientRect();
				for (let i = 0; i < trustFloaters.length; i++) {
					let trustFloater = trustFloaters[i];
					if (document.body.dir == 'rtl' || document.body.dir == 'RTL') {
						let trustFloaterAbsolute = getOffset(trustFloaterIcon);
						let rightWidthToAdd = 70;
						let floaterIconRelative = trustFloaterAbsolute.right - iconRect.right;
						let adjustedTrustFloaterRight = (floaterIconRelative - 20) * -1;
						if (rightWidthToAdd > floaterIconRelative) {
							trustFloater.style.right = adjustedTrustFloaterRight + "px";
							trustFloater.style.left = "";
						} else {
							trustFloater.style.right = "-50px";
							trustFloater.style.left = "";
						}
					} else {
						let trustFloaterAbsolute = getOffset(trustFloaterIcon);
						let leftWidthToAdd = 70;
						let floaterIconRelative = trustFloaterAbsolute.left - iconRect.left;
						let adjustedTrustFloaterLeft = (floaterIconRelative - 20) * -1;
						if (leftWidthToAdd > floaterIconRelative) {
							trustFloater.style.left = adjustedTrustFloaterLeft + "px";
							trustFloater.style.right = "";
						} else {
							trustFloater.style.left = "-50px";
							trustFloater.style.right = "";
						}
					}
				}
			}
			vm.showTrustMenu = newstate;
		}

		function getOffset(el) {
			const rect = el.getBoundingClientRect();
			return {
				left: rect.left + window.scrollX,
				top: rect.top + window.scrollY
			};
		}

		function calculateSpamWeightString() {
			vm.spamWeight = "None";
			if (!vm.messageData || !vm.messageData.message || !vm.messageData.message.header)
				return;
			var spamHeader = "X-SmarterMail-SpamAction: ";
			var index =  vm.messageData.message.header.indexOf(spamHeader);
			var endIndex = vm.messageData.message.header.indexOf(" | ", index);
			if (index > -1 && endIndex > -1) {
				var line = vm.messageData.message.header.substring(index + spamHeader.length, endIndex);
				vm.spamWeight = line;
			}
		}

		function forceTrustFloater(ev) {
			// Currently, this function doesn't do anything. If we want to show the modal on click,
			// we will also need to add a overlay div that intercepts divs so it can be closed afterwards

			//vm.isForcedMode = !vm.isForcedMode;
			//vm.showTrustMenu = vm.isForcedMode;
        }
	}

})();
