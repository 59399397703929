(function () {
	"use strict";
	angular
		.module("smartermail")
		.controller("sysAdminEditDomainController", sysAdminEditDomainController);

	function sysAdminEditDomainController($rootScope, $scope, $state, $filter, $timeout, $q, $http,
		coreDataLicensing, errorHandling, coreDataDomainSettings, claimsService, authStorage, signalrHubManager) {
		var menuItemFont = '14px Roboto,"Segoe UI","Lucida Grande","Lucida Sans Unicode",Helvetica,Arial,sans-serif';
		var menuItemSidePaddingTotal = 22;

		// Variables
		var vm = this;
		vm.idnName = $state.params.idn;
		vm.domainName = $state.params.id;
		vm.mode = true;
		vm.firstRun = true;

		ensureDomainNameDecoded();

		// Functions
		vm.selectedSectionChanged = selectedSectionChanged;

		$scope.$on("licensingChanged", onLicensingChanged);
		activate();

		////////////////////////////////

		function activate() {
			vm.windowWidth = vm.lastWindowWidth = $("#domainHeadingContainer").width();

			window.addEventListener("resize", onResize, true);
			$scope.$on("$destroy", function () {
				window.removeEventListener("resize", onResize, true);
			});

			$rootScope.secondaryImpersonationDomain = vm.domainName;
			$rootScope.secondaryImpersonationIdn = vm.idnName;
			window.stSecondaryImpersonationDomain = vm.domainName;
			$state.current.data.pageTitle = vm.idnName;
			coreDataDomainSettings.reset();
			coreDataDomainSettings.domain = vm.domainName;

			var promises = [
				$http.get("~/api/v1/settings/domain/domain"),
				coreDataDomainSettings.loadDomainPermissions()
			];

			if (!vm.idnName)
				promises.push($http.get("~/api/v1/settings/domain/data"));

			$q.all(promises).then(onLoaded, errorHandling.report);

			if (!signalrHubManager.isConnected() || signalrHubManager.connection.state.toLowerCase() != "connected") {
				var deregisterSignalRConnected = $rootScope.$on("signalRHubManagerConnected",
					function () {
						deregisterSignalRConnected();
						subscribeSysAdminToDomain();
					});
			}
			else {
				subscribeSysAdminToDomain();
			}
			$rootScope.$on("signalRHubManagerReconnected", subscribeSysAdminToDomain)

			subscribeSysAdminToDomain()

			function onLoaded(result) {
				vm.domainSettings = result[0].data.domainSettings;

				if (result.length >= 3) {
					vm.idnName = result[2].data.domainData.idnName;
					$state.current.data.pageTitle = vm.idnName;
				}

				generateMenus();
				$timeout(function () { onResize(); }, 50);
			}

			function subscribeSysAdminToDomain() {
				signalrHubManager.connection.invoke("subscribeSysAdminToDomain", authStorage.getRefreshToken(), vm.domainName);
			}
		}

		function ensureDomainNameDecoded() {
			if (vm.idnName)
				return;

			const aceFormat = /(^|[.\u3002\uFF0E\uFF61])xn--/i;
			if (!aceFormat.test(vm.domainName))
				return;

			coreDataDomainSettings.settingsData.domainData
				.then(
					function (success) {
						vm.idnName = success.idnName;
						$state.params.idn = vm.idnName;
					},
					errorHandling.report);
		}

		function onResize() {
			vm.lastWindowWidth = vm.windowWidth;
			vm.windowWidth = $("#domainHeadingContainer").width();
			if (vm.windowWidth === 0) {
				$timeout(function () { onResize(); }, 50);
			}
			onWindowWidthChanged();
		}

		vm.moreMenuSelected = false;

		function recalculateMoreMenuSelected() {
			var item = vm.selectedSection;
			vm.moreMenuSelected = item && item.showInSecondaryMenu;
			vm.moreMenuHasAnyItems = _.some(vm.combinedMenu, function (p) { return !p.hidden && p.showInSecondaryMenu });
		}

		function onWindowWidthChanged() {
			if (!vm.combinedMenu)
				return;

			// Walk through the menu items and see which ones fit
			var menuWidth = getTextWidth($filter("translate")("MORE"), menuItemFont) + menuItemSidePaddingTotal + 14;
			var runningWidth = menuWidth;

			for (var i = 0; i < vm.combinedMenu.length; i++) {
				var item = vm.combinedMenu[i];
				if (item.hidden)
					continue;

				item.itemWidth = getTextWidth(item.translated_title, menuItemFont) + menuItemSidePaddingTotal;
				runningWidth += item.itemWidth;
				item.showInSecondaryMenu = item.alwaysShowInSecondaryMenu || (runningWidth > vm.windowWidth && i >= 2);
				item.showInPrimaryMenu = !item.showInSecondaryMenu;
			}
			recalculateMoreMenuSelected();
		}

		function generateMenus() {
			if (!vm.domainSettings)
				return;

			var startPageSref = $state.current.name;

			var sortableSettings = [
				{ translated_title: $filter("translate")("EVENTS"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-events" },
				{ translated_title: $filter("translate")("SHARING"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-sharing" },
				{ translated_title: $filter("translate")("SIGNATURES"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-email-customization" },
				{ translated_title: $filter("translate")("SPAM_FILTERING"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-spam-filtering" },
				{ translated_title: $filter("translate")("CONTENT_FILTERING"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-content-filtering" },
				{ translated_title: $filter("translate")("USER_DEFAULTS"), sref: "index.sysadmin-manage.dashboard.domain-specific.user-defaults" },
				{ translated_title: $filter("translate")("SYSADMIN_SECURITY_PASSWORD_REQUIREMENTS"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-password-requirements" }
			];

			if (coreDataDomainSettings.domainPermissions.showMessageArchiveSearch)
				sortableSettings.push({
					translated_title: $filter("translate")("MESSAGE_ARCHIVE_SEARCH"),
					sref: "index.sysadmin-manage.dashboard.domain-specific.domain-message-archive"
				});
			sortableSettings = _.sortBy(sortableSettings, function (item) { return item.translated_title; });

			// Build menu
			var combinedMenuTemp = [
				{ translated_title: $filter("translate")("OPTIONS"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-config", sparams: { id: vm.domainName, idn: vm.idnName } },
				{ translated_title: $filter("translate")("ACCOUNTS"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-users" },
				{ translated_title: $filter("translate")("GENERAL"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-general-settings" },
				{ translated_title: $filter("translate")("USER_CONNECTIONS"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-user-connections" },
				{ translated_title: $filter("translate")("USER_STATUSES"), sref: "index.sysadmin-manage.dashboard.domain-specific.domain-user-statuses" }
			];
			combinedMenuTemp = combinedMenuTemp.concat(sortableSettings);
			vm.combinedMenu = combinedMenuTemp;

			var item = _.find(vm.combinedMenu, function (f) { return f.sref === startPageSref; });
			vm.selectedSection = item || vm.combinedMenu[0];

			$scope.$applyAsync();
			selectedSectionChanged();
			onResize();
		}

		function getTextWidth(text, font) {
			// re-use canvas object for better performance
			var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
			var context = canvas.getContext("2d");
			context.font = font;
			var metrics = context.measureText(text);
			return metrics.width;
		}

		function selectedSectionChanged() {
			if (!vm.firstRun) {
				if (vm.selectedSection.sparams)
					$state.go(vm.selectedSection.sref, vm.selectedSection.sparams);
				else
					$state.go(vm.selectedSection.sref);
			}

			if (vm.firstRun) {
				if ($state.current) {
					var stateName = $state.current.name;
					var section = _.find(vm.combinedMenu, function (x) { return stateName.indexOf(x.sref) > -1; });
					if (section) {
						if (section.sparams)
							$state.go(stateName, section.sparams);
						else
							$state.go(stateName);
						vm.selectedSection = section;
					} else {
						if (vm.selectedSection.sparams)
							$state.go(vm.selectedSection.sref, vm.selectedSection.sparams);
						else
							$state.go(vm.selectedSection.sref);
					}
				}
				vm.firstRun = false;
			}

			recalculateMoreMenuSelected();
		}

		function onLicensingChanged() {
			coreDataLicensing
				.init()
				.then(generateMenus, errorHandling.report);
		}
	}

})();
