(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("rtcVideoService", rtcVideoService);

	/* @ngInject */
	function rtcVideoService($rootScope, $timeout, $log, $sce, $filter, $translate, workspace, authStorage, browserNotifications, toaster) {
		var signalrUrl = stSiteRoot + "signalr/hubs";

		// Data
		var vm = this;
		vm.noCamera = false;
		vm.noScreen = false;
		vm.noMic = false;

		workspace.inCall = false;
		//vm.focusedPeer = null;
		vm.supportScreenSharing = false;
		if ((navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices))
			vm.supportScreenSharing = true;

		vm.supportsRtc = $rootScope.browserCaps.webRtcSupport;
		vm.localPeerId = uuidv4();

		vm.rtcUsers = [
			//{ id: 'ID', name: 'USERNAME', micOn: true, videoOn: true }
			{
				id: vm.localPeerId,
				attendeeId: "local",
				imgLink: "",
				avatarName: "(You)",
				name: "(You)",
				micOn: false,
				videoOn: false,
				speaking: false,
				rtcConn: null,
				active: true,
				activeOnUI: true,
				focused: false,
				streamAudio: null,
				streamVideo: null,
				sharingScreen: false,
				pinned: false
			}
		];
		vm.currentSpeakingUser = { user: null, time: 1 };
		window.rtcTest = vm.rtcUsers;



		addPeerFunctionsAndLogic(vm.rtcUsers[0]);//This sets up the local with necessary functions and logic.


		// Functions
		vm.init = init;
		vm.getActivePeers = getActivePeers;


		// Implementation
		function init() {
			
		}



	}

})();