(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSettingsUserEditController", domainSettingsUserEditController);

	function domainSettingsUserEditController($state, $rootScope, $scope, $http, $timeout, $filter, $mdDialog, $q, $translate,
		coreDataDomainSettings, coreLicensing, coreDataSettings, userDataService, claimsService, authStorage,
		impersonationService, errorHandling, successHandling, $window, localeInfoService, coreDataUserFolder) {

		var vm = this;
		var initialActiveDirectoryDomain;
		var initialActiveDirectoryUsername;
		var initialActiveDirectoryProvider;
		vm.settingCore = coreDataDomainSettings;
		vm.validateEmailAddress = coreDataSettings.validateEmailAddress;
		vm.showPasswordEnabled = false;
		vm.showPassword = false;
		vm.showAppPasswords = false;
		vm.appPasswords = {};
		vm.userTemplateSelected = false;
		vm.domain = $state.params.id || userDataService.user.domain;
		vm.userId = decodeURIComponent($state.params.selectedUserId);
		vm.timeZoneSearch = '';
		vm.textEncodeSearch = '';
		vm.isLoaded = false;
		vm.isMe = false;
		vm.canImpersonate = isAllowedToImpersonate();
		vm.showTempPassword = claimsService.isSysAdmin() || claimsService.impersonating();
		vm.usesLDAP = false;
		vm.needsPasswordForSMAuthType = false;
		vm.newPassword = "";
		vm.mailboxSpaceInput = 0;
		vm.canChangeGreylist = false;
		vm.initialEasState = false;
		vm.initialMapiEwsState = false;
		vm.allowedSizeChanges = false;
		vm.selectedLocale = "";
		vm.originalLocale = "";
		coreDataDomainSettings.searchEnabled = true;

		vm.ldapDomainCred = "";
		vm.ldapUsernameCred = "";
		vm.ldapPasswordCred = "";

		// Dropdown Options
		vm.encodingOptions = [];
		vm.availableIndexers = [];

		vm.availableLocales = [];
		for (var i = 0; i < angularLangNames.length; i++) {
			var obj = {
				n: angularLangNames[i].n,
				v: angularLangNames[i].v,
				engName: localeInfoService.getEnglishName(angularLangNames[i].v)
			}
			vm.availableLocales.push(obj);
		}

		vm.appPasswordTypes = [
			'UNUSED',
			'APP_PASSWORD_PSI',
			'APP_PASSWORD_EAS',
			'APP_PASSWORD_MAPI_EWS',
			'APP_PASSWORD_WEBDAV',
			'INTERNAL',
			'INTERNAL',
			'SERVICES',
			'WEBMAIL',
			'APP_PASSWORD_MAPI',
			'LDAP',
			'SHAREPOINT',
			'APP_PASSWORD_XMPP'
		];

		vm.fontOptions = [
			{ value: 'arial', translation: 'ARIAL' },
			{ value: 'comic sans ms', translation: 'COMIC_SANS_MS' },
			{ value: 'courier new', translation: 'COURIER_NEW' },
			{ value: 'georgia', translation: 'GEORGIA' },
			{ value: 'lucida', translation: 'LUCIDA' },
			{ value: 'tahoma', translation: 'TAHOMA' },
			{ value: 'times new roman', translation: 'TIMES_NEW_ROMAN' },
			{ value: 'trebuchet ms', translation: 'TREBUCHET_MS' },
			{ value: 'verdana', translation: 'VERDANA' }
		];

		vm.fontSizeOptions = [
			{ value: '8px', translation: 8 },
			{ value: '9px', translation: 9 },
			{ value: '1px', translation: 10 },
			{ value: '11px', translation: 11 },
			{ value: '12px', translation: 12 },
			{ value: '14px', translation: 14 },
			{ value: '16px', translation: 16 },
			{ value: '18px', translation: 18 },
			{ value: '20px', translation: 20 },
			{ value: '22px', translation: 22 },
			{ value: '24px', translation: 24 },
			{ value: '26px', translation: 26 },
			{ value: '28px', translation: 28 },
			{ value: '36px', translation: 36 },
			{ value: '48px', translation: 48 },
			{ value: '72px', translation: 72 }
		];

		vm.forwardOptions = [
			{ value: 'normal', translation: 'NORMAL' },
			{ value: 'plain', translation: 'TEXT' },
			{ value: 'attachment', translation: 'EMBED_AS_ATTACHMENT' }
		];

		vm.plusAddressOptions = [
			{ value: 'autocreate', translation: 'MOVE_TO_FOLDER' },
			{ value: '', translation: 'MOVE_TO_FOLDER_EXISTS' },
			{ value: 'inbox', translation: 'LEAVE_IN_INBOX' }
		];

		vm.previewPaneOptions = [
			{ value: 'right', translation: 'RIGHT' },
			{ value: 'none', translation: 'NONE' }
		];

		vm.replyHeaderOptions = [
			{ value: 'Basic', translation: 'BASIC' },
			{ value: 'Full', translation: 'FULL' }
		];

		vm.sortOptions = [
			{ value: 'From', translation: 'FROM' },
			{ value: 'Subject', translation: 'SUBJECT' },
			{ value: 'Size', translation: 'SIZE' },
			{ value: 'InternalDate', translation: 'DATE' }
		];

		vm.startupOptions = [
			{ value: 'today', translation: 'MY_TODAY_PAGE' },
			{ value: 'inbox', translation: 'MY_INBOX' }
		];

		vm.timeZoneOptions = [];

		vm.throttleOptions = [
			{ value: 2, translation: 'NONE' },
			{ value: 1, translation: 'DELAY' },
			{ value: 0, translation: 'REJECT' }
		];

		vm.userStatusOptions = [
			{ value: { isEnabled: true, canReceiveMail: true }, translation: 'ENABLED' },
			{ value: { isEnabled: false, canReceiveMail: true }, translation: 'DOMAIN_USERS_DISABLED_ALLOW_MAIL' },
			{ value: { isEnabled: false, canReceiveMail: false }, translation: 'DOMAIN_USERS_DISABLED_DISALLOW_MAIL' },
		];

		var indexingStatus = {
			0: "NONE",
			1: "QUEUED",
			2: "INDEXING",
			3: "OPTIMIZING",
			4: "COMPLETED"
		};

		// Functions
		vm.clearSearchTerm = function () { vm.timeZoneSearch = ''; vm.textEncodeSearch = ''; };
		vm.userStatus = userStatus;
		vm.defaultUserGroups = defaultUserGroups;
		vm.editUserDropdown = editUserDropdown;
		vm.getUserStatus = getUserStatus;
		vm.loadEditUser = loadEditUser;
		vm.onShowPasswordClicked = onShowPasswordClicked;
		vm.showAppPassword = showAppPassword;
		vm.resetAppPassword = resetAppPassword;
		vm.impersonateUser = impersonateUser;
		vm.openChangePassword = openChangePassword;
		vm.openExpirePassword = openExpirePassword;
		vm.openChangeUsername = openChangeUsername;
		vm.generateTempPassword = generateTempPassword;
		vm.extendTempPassword = extendTempPassword;
		vm.revokeTempPassword = revokeTempPassword;
		vm.searchItems = searchItems;
		vm.updateForwardList = updateForwardList;
		vm.validDate = validDate;
		vm.dateExpired = dateExpired;
		vm.deleteThis = deleteThis;
		vm.changeAuthType = changeAuthType;
		vm.save = save;
		vm.preSave = preSave;

		vm.navigateUp = function (form) { form.$setPristine(); $state.go("^"); }
		vm.reindexThis = reindexThis;
		vm.recalculateThis = recalculateThis;
		vm.resyncUser = resyncUser;
		vm.getTranslatedThrottleOption = getTranslatedThrottleOption;
		vm.disableUserTwoFactor = disableUserTwoFactor;
		vm.serviceAccessControlledBySysAdmin = serviceAccessControlledBySysAdmin;
		vm.enableEasCheckbox = false;
		vm.mailForwardingChanged = mailForwardingChanged;
		vm.enableMapiCheckbox = false;
		vm.onCopyPassword = onCopyPassword;
		vm.onCopyTempPassword = onCopyTempPassword;
		vm.redirect = function (url) {
			$window.location.href = url;
		}
		activate();

		//////////////////////////////////

		function activate() {
			setPageTitle();

			recalculateLicense();
			coreLicensing.watchForChanges($scope, recalculateLicense);

			$q.all([coreDataSettings.init(), coreDataUserFolder.init()])
				.then(onInitPart1, errorHandling.report);

			function onInitPart1() {
				$http
					.get("~/api/v1/settings/domain/domain")
					.then(function (data) {
						vm.domainSettings = data.data.domainSettings;
						onInitialized();
					}, function () { });
			}

			function onInitialized() {
				vm.canChangeGreylist = coreDataSettings.userPermissions.greyListEnabled;
				vm.canChangeTwoFactor = coreDataSettings.userPermissions.enableTwoFactor;
				vm.forwardingEnabled = vm.domainSettings.enableMailForwarding;
				vm.authProviders =
					vm.domainSettings.authenticationProviders.map(p => ({
						"id": p.providerId,
						"name": p.providerName,
						"type": p.providerType,
						"label": toLabel( p.providerType, p.providerName),
						"domainname": p.fqdn
					}));
				vm.authProviders.unshift({
					"id": "",
					"name": $filter('translate')("SMARTERMAIL"),
					"type": 0,
					"label": toLabel(0),
					"domainname": ""
				});
				function toLabel(type, name) {
					let prefix = ""
					switch (type) {
						case 0:
							prefix = $filter('translate')("SMARTERMAIL");
							break;
						case 1:
							prefix = $filter('translate')("ACTIVE_DIRECTORY");
							break;
						case 3:
							prefix = $filter('translate')("LDAP");
							break;
						default:
							prefix = $filter('translate')("UNKNOWN");
							break;
					}

					return name ? `${prefix}: ${name}` : prefix;
				}
				// User Cards
				vm.modUser = undefined;

				// Load options

				coreDataSettings.settingsData.availiableTimeZones
					.then(function (success) {
						vm.timeZoneOptions = success;
						for (var i = 0; i < vm.timeZoneOptions.length; ++i) {
							vm.timeZoneOptions[i].translated = $filter('translate')(vm.timeZoneOptions[i].displayName);
						}
					});

				vm.allowedSizeChanges = (claimsService.impersonating() || claimsService.isSysAdmin()) || (vm.domainSettings && vm.domainSettings.allowUserSizeChanging);

				vm.enableEasCheckbox = vm.domainSettings && vm.activeSyncLicensed &&
					(claimsService.impersonating() || claimsService.isSysAdmin() || vm.domainSettings.enableActiveSyncAccountManagement);
				vm.enableMapiCheckbox = vm.domainSettings && vm.mapiEwsLicensed &&
					(claimsService.impersonating() || claimsService.isSysAdmin() || vm.domainSettings.enableMapiEwsAccountManagement);

				// Watches
				$scope.$watchCollection(function () {
					if (vm.modUser && vm.modUser.mailboxForwardList)
						return vm.modUser.mailboxForwardList.forwardList;
					else return null;
				}, function () {
					if (vm.modUser !== undefined)
						vm.updateForwardList();
				});

				vm.loadEditUser(decodeURIComponent(vm.userId))
					.then(onLoadEditUser, errorHandling.report);

				function onLoadEditUser() {
					$scope.$watch(function () {
						return vm.modUser.userMailSettings.isEasEnabled;
					}, function (newValue, oldValue) {
						if (newValue == undefined && oldValue == undefined) return;
						if (newValue === oldValue) return;

						vm.domainEasCount += newValue ? 1 : -1;
						vm.systemEasCount += newValue ? 1 : -1;
					});

					$scope.$watch(function () {
						return vm.modUser.userMailSettings.isMapiEwsEnabled;
					}, function (newValue, oldValue) {
						if (newValue == undefined && oldValue == undefined) return;
						if (newValue === oldValue) return;

						vm.domainMapiEwsCount += newValue ? 1 : -1;
						vm.systemMapiEwsCount += newValue ? 1 : -1;
					});
				}
			}
		}

		function recalculateLicense() {
			vm.edition = coreLicensing.edition;
			vm.activeSyncLicensed = coreLicensing.hasEas;
			vm.mapiEwsLicensed = coreLicensing.hasMapiEws;
			vm.isEnterprise = coreLicensing.edition != coreLicensing.EDITION_PROFESSIONAL;
		}

		function serviceAccessControlledBySysAdmin() {
			if (claimsService.impersonating() || claimsService.isSysAdmin())
				return false;

			if (!vm.activeSyncLicensed || !vm.mapiEwsLicensed)
				return true;
			if (!vm.canAddEasAccounts || !vm.canAddMapiEwsAccounts)
				return true;

			if (vm.isImpersonating)
				return false;
			return (!vm.domainSettings ||
				!vm.domainSettings.enableActiveSyncAccountManagement ||
				!vm.domainSettings.enableMapiEwsAccountManagement);
		}

		function impersonateUser() {
			if (!isAllowedToImpersonate())
				return;
			impersonationService.impersonateUser(vm.editingCard.emailAddress);
		}

		function isAllowedToImpersonate() {
			return claimsService.canImpersonate() || claimsService.impersonating();
		}

		function reindexThis() {
			var confirm = $mdDialog.confirmDeletion()
				.title($translate.instant("CONFIRMATION_REQUIRED"))
				.textContent($translate.instant('CONFIRMATIONS_REINDEX'))
				.ok($translate.instant('REINDEX'))
				.noWarn(true);
			$mdDialog.show(confirm).then(startReindex, function () { });
			function startReindex() {
				$http
					.post('~/api/v1/settings/domain/reindex-users', JSON.stringify({ input: [vm.userId.split('@')[0]] }))
					.then(successHandling.report, errorHandling.report);
			}
		}

		function recalculateThis() {
			$http
				.post('~/api/v1/settings/domain/recalculate-users', JSON.stringify({ input: [vm.userId.split('@')[0]] }))
				.then(successHandling.report, errorHandling.report);
		}

		function resyncUser() {


			var confirm = $mdDialog.confirm()
				.title($filter("translate")("CONFIRMATION_REQUIRED"))
				.textContent($translate.instant("CONFIRMATIONS_RESYNC_USERS", { items: 1 }))
				.cancel($filter("translate")("CANCEL"))
				.ok($filter("translate")("CONTINUE"));

			$mdDialog.show(confirm).then(function (option) { doResyncUsers(); });

			function doResyncUsers() {
				var apiEndpoint = `~/api/v1/settings/domain/resync-users/${vm.domain}`;

				$http.post(apiEndpoint, JSON.stringify({ input: [vm.userId.split('@')[0]] }))
					.then(function (success) {
						successHandling.report($translate.instant("RESYNC_ACTION_MESSAGE", { count: 1 }));
					}, errorHandling.report);
			}
		}
		function setPageTitle() {
			$state.current.data.pageTitle = vm.userId == "default" ? $filter("translate")("USER_DEFAULTS") : ($filter("translate")("USER") + " (" + vm.userId + ")");
		}

		function preSave(event, form) {
			function promptForLdapCredentials() {
				$mdDialog.show({
					controller: "getLdapCredentialsController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/users/get-ldap-credentials.dlg.html",
					targetEvent: event,
				}).then(function (response) {
					verifyLdapUser(response.ldapUser, response.ldapPassword, response.rememberCredentials);
				});

			}
			function verifyLdapUser(ldapAdminUser, ldapAdminPw, rememberCredentials) {
				$http.post("~/api/v1/settings/domain/ldap-usernames",
					JSON.stringify({
						ldapProviderId: vm.authProvider,
						ldapUsername: ldapAdminUser,
						ldapPassword: ldapAdminPw,
						rememberCredentials: rememberCredentials
					}))
					.then(
						function (success) {
							var authUsername = vm.modUser.userData.adUsername || vm.modUser.userData.userName;
							var ldapUser = success.data.usernames.find(contact => contact.toLowerCase() ===
								authUsername.toLowerCase());
							if (ldapUser) {
								save(event, form);
							} else {
								errorHandling.report($filter("translate")("ERROR_ACTIVE_DIRECTORY_USERNAME_NOT_FOUND_ON_DOMAIN"));
							}
						},
						function (failed) {
							if (failed.data.message === "LDAP_ANONYMOUS_QUERIES_NOT_ALLOWED") {
								promptForLdapCredentials();
							} else {
								errorHandling.report(failed);
							}
						});
			}
			const activeDirectoryChanged =
				vm.authProvider.providerType !== 0 && vm.authProvider.id !== vm.modUser.userData.securityFlags.ldapProviderId &&
				vm.modUser.userData.adUsername !== initialActiveDirectoryUsername;

			if (activeDirectoryChanged && vm.modUser.userData.securityFlags.authType === 1) {
				if (!vm.domainSettings.activeDirectoryIntegration || !vm.authProvider)
					errorHandling.report($filter("translate")("ERROR_ACTIVE_DIRECTORY_USERNAME_NOT_FOUND_ON_DOMAIN"));
				else {
					verifyLdapUser();
				}
			} else {
				save(event, form);
			}

		}

		function save(event, form) {
			if (!form.$valid)
				return;

			$rootScope.spinner.show();
			savePart1()
				.then(savePart2)
				.then(savePart3)
				.then(savePart4)
				.then(saveAutoClean)
				.then(saveDefaultFolders)
				.then(function () { vm.navigateUp(form); }, errorHandling.report)
				.catch(errorHandling.report)
				.finally($rootScope.spinner.hide);



			function savePart1() {
				vm.modUser.userMailSettings.maxSize = vm.mailboxSpaceInput * (1024 * 1024);
				if (vm.authProvider.id !== vm.modUser.userData.securityFlags.authProviderId) {
					vm.modUser.userData.securityFlags.authType = vm.authProvider.type;
					vm.modUser.userData.securityFlags.authProviderId = vm.authProvider.id;
					vm.modUser.userData.securityFlags.authenticatingWindowsDomain = vm.authProvider.domainname;
				}
				var userDataParams = {
					securityFlags: {
						authType: vm.modUser.userData.securityFlags.authType,
						authProviderId: vm.modUser.userData.securityFlags.authProviderId,
						authenticatingWindowsDomain: vm.modUser.userData.securityFlags.authenticatingWindowsDomain,
						isDomainAdmin: vm.modUser.userData.securityFlags.isDomainAdmin
					},
					adUsername: vm.modUser.userData.adUsername,
					fullName: vm.modUser.userData.fullName,
					passwordLocked: vm.modUser.userData.passwordLocked,
				};

				if (vm.newPassword != "")
					userDataParams.password = vm.newPassword;
				var clientId = authStorage.getClientId();
				return $http.post('~/api/v1/settings/domain/post-user', JSON.stringify({ email: vm.modUser.userData.emailAddress, userData: userDataParams, clientId: clientId }));
			}

			function savePart2() {
				var userMailParams = {
					deleteOption: vm.modUser.userMailSettings.deleteOption,
					markPOP3DownloadsAsRead: vm.modUser.userMailSettings.markPOP3DownloadsAsRead,
					composeFont: vm.modUser.userMailSettings.composeFont,
					composeFontSize: vm.modUser.userMailSettings.composeFontSize,
					requestReadReceipts: vm.modUser.userMailSettings.requestReadReceipts,
					replyFromToField: vm.modUser.userMailSettings.replyFromToField,
					requestDeliveryReceipts: vm.modUser.userMailSettings.requestDeliveryReceipts,
					throttleSettings: {
						messagesPerHour: vm.modUser.userMailSettings.throttleSettings.messagesPerHour,
						messagesAction: vm.modUser.userMailSettings.throttleSettings.messagesAction,
						bandwidthPerHour: vm.modUser.userMailSettings.throttleSettings.bandwidthPerHour,
						bandwidthAction: vm.modUser.userMailSettings.throttleSettings.bandwidthAction
					},
					plusAddressingFolder: vm.plusAddressing == 'disabled' ? '' : vm.plusAddressing,
					plusAddressingEnabled: vm.plusAddressing != 'disabled',
					serviceLevelMap: encodeServiceLevelMap(),
					replyToAddress: vm.modUser.userMailSettings.replyToAddress,
					timeZoneIndex: vm.modUser.userMailSettings.timeZoneIndex,
					backupEmailAddress: vm.modUser.userMailSettings.backupEmailAddress,
					isEasEnabled: vm.modUser.userMailSettings.isEasEnabled,
					isEnabled: vm.modUser.userMailSettings.isEnabled,
					isMapiEwsEnabled: vm.modUser.userMailSettings.isMapiEwsEnabled,
					canReceiveMail: vm.modUser.userMailSettings.canReceiveMail,
					maxSize: vm.modUser.userMailSettings.maxSize,
					disableGreylisting: !vm.modUser.userMailSettings.disableGreylisting,
					allowRemoteContent: vm.modUser.userMailSettings.allowRemoteContent,
					previewUsePreview: vm.modUser.userMailSettings.previewPaneOption !== "none",
					enableMailForwarding: vm.modUser.userMailSettings.enableMailForwarding,
					preferredIndexer: vm.modUser.userMailSettings.preferredIndexer,
					userContactInfo: { hideFromLdap: vm.modUser.userMailSettings.userContactInfo.hideFromLdap },
					defaultComposeDomain: vm.modUser.userMailSettings.defaultComposeDomain,
					remoteContentExceptions: vm.modUser.userMailSettings.remoteContentExceptions,
					remoteTrackersExceptions: vm.modUser.userMailSettings.remoteTrackersExceptions,
					draftAutoSave: vm.modUser.userMailSettings.draftAutoSave,
					hideMailAvatars: vm.modUser.userMailSettings.hideMailAvatars,
					localeID: vm.selectedLocale
				};

				return $http.post('~/api/v1/settings/domain/post-user-mail', JSON.stringify({ email: vm.modUser.userData.emailAddress, userMailSettings: userMailParams }));
			}

			function savePart3() {
				var forwardListParams = {
					forwardList: vm.modUser.mailboxForwardList.forwardList,
					deleteOnForward: vm.modUser.mailboxForwardList.deleteOnForward,
					keepRecipients: vm.modUser.mailboxForwardList.keepRecipients
				};
				return $http.post('~/api/v1/settings/domain/post-mailbox-forward-list', JSON.stringify({ email: vm.modUser.userData.emailAddress, mailboxForwardList: forwardListParams }));
			}

			function savePart4() {
				var promises = [];
				promises.push($http.post('~/api/v1/settings/domain/all-user-groups/' + vm.modUser.userData.userName + '/', JSON.stringify({ userGroups: vm.modUser.userGroups })));
				return $q.all(promises);
			}
			function saveAutoClean() {

				if (!vm.autoCleanSettings.allowUserOverride)
					return $q.when();
				var params = {
					userOverrideActive: vm.autoCleanSettings.userOverrideActive,
					userRules: vm.autoCleanSettings.userRules
				};
				return $http.post(`~/api/v1/settings/${vm.modUser.userData.userName}/auto-clean`, JSON.stringify(params));
			}
			function saveDefaultFolders() {
				var promises = [];
				if (vm.calendarDefault && !vm.calendarDefault.isPrimary)
					promises.push($http.post('~/api/v1/settings/set-folder-as-primary/' + vm.modUser.userData.userName,
						{
							input: vm.calendarDefault.folderGuid
						}));
				if (vm.notesDefault && !vm.notesDefault.isPrimary)
					promises.push($http.post('~/api/v1/settings/set-folder-as-primary/' + vm.modUser.userData.userName,
						{
							input: vm.notesDefault.folderGuid
						}));
				if (vm.tasksDefault && !vm.tasksDefault.isPrimary)
					promises.push($http.post('~/api/v1/settings/set-folder-as-primary/' + vm.modUser.userData.userName,
						{
							input: vm.tasksDefault.folderGuid
						}));
				if (vm.contactsDefault && !vm.contactsDefault.isPrimary)
					promises.push($http.post('~/api/v1/settings/set-folder-as-primary/' + vm.modUser.userData.userName,
						{
							input: vm.contactsDefault.folderGuid
						}));
				if (promises.length === 0)
					return $q.resolve();

				return $q.all(promises);
			}
		}

		function deleteThis(event, form) {
			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_USERS", { items: 1 }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, function () { });

			function doDelete() {
				$http
					.post("~/api/v1/settings/domain/users-delete", JSON.stringify({ input: [vm.userId] }))
					.then(function () { vm.navigateUp(form); }, errorHandling.report);
			}
		}

		function userStatus(value) {
			if (arguments.length) {
				vm.modUser.userMailSettings.isEnabled = value.value.isEnabled;
				vm.modUser.userMailSettings.canReceiveMail = value.value.canReceiveMail;
				userStatus = value;
			} else {
				var findStatus = $.grep(vm.userStatusOptions, function (option) { return option.translation === vm.getUserStatus(); });
				if (findStatus.length > 0) { userStatus = findStatus[0]; return userStatus; }
			}
		}

		function getUserStatus() {
			if (!vm.modUser || !vm.modUser.userMailSettings)
				return null;
			if (vm.modUser.userMailSettings.isEnabled && vm.modUser.userMailSettings.canReceiveMail)
				return "ENABLED";
			else if (!vm.modUser.userMailSettings.isEnabled && vm.modUser.userMailSettings.canReceiveMail)
				return "DOMAIN_USERS_DISABLED_ALLOW_MAIL";
			else if (!vm.modUser.userMailSettings.isEnabled && !vm.modUser.userMailSettings.canReceiveMail)
				return "DOMAIN_USERS_DISABLED_DISALLOW_MAIL";
		}

		function initDropSearch() {
			$timeout(function () {
				$('#timeZoneSearch').on('keydown', function (ev) { ev.stopPropagation(); });
				$('#textEncodeSearch').on('keydown', function (ev) { ev.stopPropagation(); });
			});
		}

		var loadEditUserDefer = null;
		function loadEditUser(email) {
			if (loadEditUserDefer) return loadEditUserDefer.promise;
			loadEditUserDefer = $q.defer();
			$rootScope.spinner.show();

			vm.editingCard = { emailAddress: email, userName: email.split('@')[0] };
			var promises = [];
			promises.push($http.post('~/api/v1/settings/domain/get-user', JSON.stringify({ email: email })));
			promises.push($http.post('~/api/v1/settings/domain/get-user-mail', JSON.stringify({ email: email })));
			promises.push($http.post('~/api/v1/settings/domain/get-mailbox-forward-list', JSON.stringify({ email: email })));
			promises.push($http.get('~/api/v1/settings/domain/all-user-groups/' + email.split('@')[0] + '/'));
			promises.push($http.get('~/api/v1/settings/domain/indexing-user/' + email.split('@')[0] + '/'));
			promises.push($http.get('~/api/v1/settings/available-indexers'));
			promises.push($http.post('~/api/v1/settings/domain/get-available-app-passwords', JSON.stringify({ token: authStorage.getToken(), emailAddress: email })));
			promises.push($http.get("~/api/v1/licensing/domain-info"));
			promises.push($http.get(`~/api/v1/settings/${email.split('@')[0]}/auto-clean`).then(onAutoCleanSettingsLoad));
			promises.push($http.get(`~/api/v1/folders/list-owned-folders/${email.split('@')[0]}/2`).then(success => {
				vm.notesFolders = success.data.folders;
				vm.notesDefault = vm.notesFolders.find(folder => folder.isPrimary);
			})
			); // 8 Notes
			promises.push($http.get(`~/api/v1/folders/list-owned-folders/${email.split('@')[0]}/3`).then(success => {
				vm.contactsFolders = success.data.folders;
				vm.contactsDefault = vm.contactsFolders.find(folder => folder.isPrimary);
			})
			); // 9 Contacts
			promises.push($http.get(`~/api/v1/folders/list-owned-folders/${email.split('@')[0]}/4`).then(success => {
				vm.calendarFolders = success.data.folders;
				vm.calendarDefault = vm.calendarFolders.find(folder => folder.isPrimary);
			})
			); // 10 Appointments
			promises.push($http.get(`~/api/v1/folders/list-owned-folders/${email.split('@')[0]}/5`).then(success => {
				vm.tasksFolders = success.data.folders;
				vm.tasksDefault = vm.tasksFolders.find(folder => folder.isPrimary);
			})
			);  // 11 Tasks
			promises.push($http.get(`~/api/v1/settings/delegation/${email.split('@')[0]}`).then(success => {
				vm.hasDelegation = success.data.delegateAccounts && success.data.delegateAccounts.length > 0;
			})
			);
			promises.push($http.get('~/api/v1/settings/from-address-options/' + email.split('@')[0]).then(success => {
				vm.fromAddressOptions = success.data.options;
			})
			);
			$q.all(promises)
				.then(function (success) {
					var userInfo = { userData: success[0].data.userData, userMailSettings: success[1].data.userMailSettings, mailboxForwardList: success[2].data.mailboxForwardList, userGroups: success[3].data.userGroups }
					vm.modUser = $.extend(true, {}, userInfo);
					vm.authProvider =
						vm.authProviders.find(p => p.id === vm.modUser.userData.securityFlags.authProviderId);
					vm.usesLDAP = vm.modUser.userData.securityFlags.authType == 1;
					vm.modUser.userMailSettings.previewPaneOption = vm.modUser.userMailSettings.previewUsePreview ? "right" : "none";
					vm.isMe = userInfo.userData.emailAddress === userDataService.user.emailAddress;
					vm.indexingUser = success[4].data.indexingUser;
					if (vm.indexingUser) {
						vm.translatedIndexingDate = $filter("date")(vm.indexingUser.dateAddedToList, "short");
						vm.indexingUser.translatedStatus = $translate.instant(indexingStatus[vm.indexingUser.currentStatus]);
						vm.timeoutPromise = $timeout(updateIndexingStatus, 5000);
					}

					vm.selectedLocale = success[1].data.userMailSettings.localeId;
					vm.originalLocale = vm.selectedLocale;

					vm.modUser.userMailSettings.disableGreylisting = !vm.modUser.userMailSettings.disableGreylisting;

					vm.availableIndexers = success[5].data.indexers || ['Lucene_Base'];
					vm.isImpersonating = claimsService.impersonating();
					vm.showPasswordEnabled = !vm.usesLDAP &&
						(
							(claimsService.isSysAdmin() && claimsService.canViewPasswords()) ||
							(!claimsService.isSysAdmin() && claimsService.impersonating() && claimsService.canViewPasswords()) ||
							(!claimsService.isSysAdmin() && !claimsService.impersonating() && vm.domainSettings.enableShowPasswords)
						);
					vm.showPassword = false;
					vm.twoFactorEnabled = vm.domainSettings.twoFactorSettings.enable && vm.domainSettings.twoFactorSettings.setting !== 0 && userInfo.userMailSettings.twoFactorSettings.isSetup;
					vm.canShowAppPasswords = vm.twoFactorEnabled && vm.showPasswordEnabled;
					decodeServiceLevelMap();
					vm.updateForwardList();
					vm.defaultUserGroups();

					vm.initialEasState = vm.modUser.userMailSettings.isEasEnabled;
					vm.initialMapiEwsState = vm.modUser.userMailSettings.isMapiEwsEnabled;

					vm.oldUserData = $.extend(true, {}, vm.modUser.userData);
					vm.oldUserMailSettings = $.extend(true, {}, vm.modUser.userMailSettings);
					vm.oldMailboxForwardList = $.extend(true, {}, vm.modUser.mailboxForwardList);
					vm.oldUserGroups = $.extend(true, [], vm.modUser.userGroups);

					vm.editingCard.securityFlags = {};
					vm.editingCard.securityFlags.authType = vm.modUser.userData.securityFlags.authType;
					vm.editingCard.securityFlags.authenticatingWindowsDomain = vm.modUser.userData.securityFlags.authenticatingWindowsDomain;
					vm.editingCard.adUsername = vm.modUser.userData.adUsername;
					vm.editingCard.fullName = vm.modUser.userData.fullName;
					vm.editingCard.securityFlags.isDomainAdmin = vm.modUser.userData.securityFlags.isDomainAdmin;
					vm.editingCard.securityFlags.isDisabled = !vm.modUser.userMailSettings.isEnabled;
					vm.editingCard.userName = vm.modUser.userData.userName;
					vm.editingCard.emailAddress = vm.modUser.userData.emailAddress;
					initDropSearch();

					vm.plusAddressing = vm.modUser.userMailSettings.plusAddressingEnabled ? vm.modUser.userMailSettings.plusAddressingFolder : 'disabled';
					vm.pageForm.$setSubmitted();

					if (!vm.domainSettings.sharedGlobalAddressList)
						vm.modUser.userMailSettings.userContactInfo.hideFromLdap = true;

					if (!vm.domainSettings.enableXmpp)
						vm.enableXMPP = false;

					vm.appPasswordsAvailable = success[6].data.appPasswords;
					vm.appPasswordsShow = {};
					for (var i = 0; i < vm.appPasswordsAvailable.length; ++i) {
						vm.appPasswordsShow[vm.appPasswordsAvailable[i]] = false;
					}

					vm.mailboxSpaceInput = vm.modUser.userMailSettings.maxSize / (1024 * 1024);

					vm.systemEasLimit = success[7].data.data.systemEasLimit;
					vm.systemEasCount = success[7].data.data.systemEasCount;
					vm.domainEasLimit = success[7].data.data.domainEasLimit;
					vm.domainEasCount = success[7].data.data.domainEasCount;

					vm.systemMapiEwsLimit = success[7].data.data.systemMapiEwsLimit;
					vm.systemMapiEwsCount = success[7].data.data.systemMapiEwsCount;
					vm.domainMapiEwsLimit = success[7].data.data.domainMapiEwsLimit;
					vm.domainMapiEwsCount = success[7].data.data.domainMapiEwsCount;

					vm.canAddEasAccounts =
						(vm.systemEasLimit === -1 || vm.systemEasCount <= vm.systemEasLimit) &&
						(vm.domainEasLimit === 0 || vm.domainEasCount <= vm.domainEasLimit);

					vm.canAddMapiEwsAccounts =
						vm.systemMapiEwsCount < vm.systemMapiEwsLimit &&
						(vm.domainMapiEwsLimit === 0 || vm.domainMapiEwsCount < vm.domainMapiEwsLimit);

					initialActiveDirectoryUsername = vm.modUser.userData && vm.modUser.userData.adUsername;
					initialActiveDirectoryProvider = vm.modUser.userData.securityFlags.ldapProviderId;

					loadEditUserDefer.resolve();
				}, function (failure) {
					errorHandling.report(failure);
					loadEditUserDefer.reject();
				})
				.finally(function () {
					loadEditUserDefer = null;
					vm.isLoaded = true;
					$rootScope.spinner.hide();
					$rootScope.$broadcast("masonry:contentsChanged");
				});

			return loadEditUserDefer.promise;
		}

		function searchItems() {
			coreDataDomainSettings.searchPredicate = { emailAddress: coreDataDomainSettings.searchText };
			if (!coreDataDomainSettings.searchText || coreDataDomainSettings.searchText.length === 0)
				coreDataDomainSettings.searchPredicate = undefined;

			coreDataDomainSettings.updateFilteredCards();
		}

		function editUserDropdown(params) {
			coreDataDomainSettings.editItem(params.card, params.event);
		}

		function updateForwardList() {
			if (!vm.modUser.mailboxForwardList.forwardList)
				vm.modUser.mailboxForwardList.forwardList = [];

			if (vm.modUser.mailboxForwardList.forwardList.length === 0) {
				vm.modUser.mailboxForwardList.forwardList.push('');
			} else {
				//remove empty address fields from the list.
				if (vm.modUser.mailboxForwardList.forwardList.length > 0) {
					var emptyAdds = $.grep(vm.modUser.mailboxForwardList.forwardList, function (add) { return add === ''; });
					if (emptyAdds.length > 1) {
						vm.modUser.mailboxForwardList.forwardList = $.grep(vm.modUser.mailboxForwardList.forwardList, function (add) { return add !== ''; });
						vm.modUser.mailboxForwardList.forwardList.push('');
					} else if (emptyAdds.length === 0) {
						vm.modUser.mailboxForwardList.forwardList.push('');
					}
				}
			}
		}

		function defaultUserGroups() {
			for (var i = 0; i < vm.modUser.userGroups.length; i++) {
				if (vm.modUser.userGroups[i].enabled === undefined)
					vm.modUser.userGroups[i].enabled = false;
			}
		}

		function decodeServiceLevelMap() {
			vm.enablePop = (vm.modUser.userMailSettings.serviceLevelMap & 0x01) !== 0;
			vm.enableImap = (vm.modUser.userMailSettings.serviceLevelMap & 0x02) !== 0;
			vm.enableIncomingSMTP = (vm.modUser.userMailSettings.serviceLevelMap & 0x04) !== 0;
			vm.enableOutgoingSMTP = (vm.modUser.userMailSettings.serviceLevelMap & 0x8) !== 0;
			vm.enableWebmail = (vm.modUser.userMailSettings.serviceLevelMap & 0x10) !== 0;
			vm.enableXMPP = (vm.modUser.userMailSettings.serviceLevelMap & 0x20) !== 0;
			vm.enableWebdav = (vm.modUser.userMailSettings.serviceLevelMap & 0x40) !== 0;
		}

		function encodeServiceLevelMap() {
			var ret = 0;
			if (vm.enablePop)
				ret |= 0x01;
			if (vm.enableImap)
				ret |= 0x02;
			if (vm.enableIncomingSMTP)
				ret |= 0x04;
			if (vm.enableOutgoingSMTP)
				ret |= 0x8;
			if (vm.enableWebmail)
				ret |= 0x10;
			if (vm.enableXMPP)
				ret |= 0x20;
			if (vm.enableWebdav)
				ret |= 0x40;

			return ret;
		}

		function openChangeUsername(ev, form) {
			if (vm.isMe) {
				return;
			}
			$mdDialog.show({
				locals: {
					oldUsername: vm.modUser.userData.userName
				},
				controller: "domainEditUsernameController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/domain-settings/users/edit-username.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					vm.newUsername = modalSuccess.newUsername;
					if (vm.newUsername.indexOf("@") > -1)
						vm.newUsername = vm.newUsername.split("@")[0];

					var clientId = authStorage.getClientId();

					$http.post('~/api/v1/settings/domain/rename-user/' + vm.modUser.userData.userName + '/' + vm.newUsername, { clientId: clientId })
						.then(function () {
							form.$setPristine();
							$state.go('^.specific', { selectedUserId: encodeURIComponent(vm.newUsername + "@" + vm.domain) });
						}, errorHandling.report);
				});
		}

		function generateTempPassword(ev) {
			var params = JSON.stringify({ email: vm.modUser.userData.emailAddress });
			$http
				.post('~/api/v1/auth/generate-temp-pass', params)
				.then(function (success) {
					vm.modUser.userData.tempPassword = success.data.tempPassword;
					vm.modUser.userData.tempPasswordExpUtc = success.data.tempPasswordExpUtc;
				}, errorHandling.report);
		}

		function extendTempPassword(ev) {
			var params = JSON.stringify({ email: vm.modUser.userData.emailAddress });
			$http
				.post('~/api/v1/auth/extend-temp-pass', params)
				.then(function (success) {
					vm.modUser.userData.tempPassword = success.data.tempPassword;
					vm.modUser.userData.tempPasswordExpUtc = success.data.tempPasswordExpUtc;
				}, errorHandling.report);
		}

		function revokeTempPassword(ev) {
			var params = JSON.stringify({ email: vm.modUser.userData.emailAddress });
			$http
				.post('~/api/v1/auth/revoke-temp-pass/', params)
				.then(function (success) {
					vm.modUser.userData.tempPassword = success.data.tempPassword;
					vm.modUser.userData.tempPasswordExpUtc = success.data.tempPasswordExpUtc;
				}, errorHandling.report);
		}

		function openChangePassword(ev) {
			$mdDialog
				.show({
					controller: "userEditPasswordAdminController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/change-user-password-admin.dlg.html",
					locals: { username: vm.modUser.userData.userName },
					targetEvent: ev
				})
				.then(function (modalSuccess) {
					if (modalSuccess.newPassword === "" || modalSuccess.confirmPassword === "") {
						errorHandling.report("INVALID_PASSWORDS");
						return;
					} else if (modalSuccess.newPassword !== modalSuccess.confirmPassword) {
						errorHandling.report("PASSWORDS_DONT_MATCH");
						return;
					}
					var clientId = authStorage.getClientId();
					var params = JSON.stringify({ email: vm.modUser.userData.emailAddress, userData: { password: modalSuccess.newPassword }, clientId: clientId });
					$http
						.post("~/api/v1/settings/domain/post-user", params)
						.then(function () { successHandling.report(); }, errorHandling.report);
				});
		}

		function openExpirePassword(ev) {
			var confirm = $mdDialog.confirmDeletion()
				.title($translate.instant("CONFIRMATION_REQUIRED"))
				.textContent($filter('translate')('EXPIRE_PASSWORD_MESSAGE', { count: 1 }))
				.ok($translate.instant('EXPIRE_PASSWORD'))
				.noWarn(true);
			$mdDialog.show(confirm).then(function () {
				var params = JSON.stringify({ emailAddresses: [vm.modUser.userData.emailAddress] });
				$rootScope.spinner.show();
				$http
					.post('~/api/v1/settings/expire-passwords', params)
					.then(function (success) {
						if (success.data.activeDirectoryCount > 0)
							errorHandling.warn('EXPIRE_PASSWORD_ACTIVEDIRECTORY');
						else
							successHandling.report();
					}, errorHandling.report)
					.finally($rootScope.spinner.hide);
			});
		}

		function validDate(date) {
			return moment(date).year() > 100;
		}

		function dateExpired(date) {
			if (date == undefined) {
				return false;
			}

			var now = moment();
			var dateToCheck = moment(date);
			if (now > dateToCheck) {
				return true;
			} else {
				return false;
			}
		}

		function updateIndexingStatus() {
			$http.get('~/api/v1/settings/domain/indexing-user/' + vm.modUser.userData.userName + '/').then(function (success) {
				if (!coreDataDomainSettings.editingItem) return;
				if (!success.data.indexingUser) {
					vm.indexingUser.itemsIndexed = vm.indexingUser.itemsToIndex;
					vm.indexingUser.translatedStatus = $translate.instant(indexingStatus[4]);
					return;
				}

				vm.indexingUser = success.data.indexingUser;
				vm.indexingUser.translatedStatus = $translate.instant(indexingStatus[vm.indexingUser.currentStatus]);

				vm.timeoutPromise = $timeout(updateIndexingStatus, 5000);
			}, function (failure) {

			});
		}

		function onShowPasswordClicked(event, show) {
			if (!vm.password) {
				$rootScope.spinner.show();
				var params = JSON.stringify({
					token: authStorage.getToken(),
					emailAddress: vm.editingCard.emailAddress
				});
				$http.post("~/api/v1/settings/domain/show-password/", params)
					.then(function (success) {
						vm.showPassword = true;
						vm.password = success.data.password;
						var temp = success.data.appPasswords;
						vm.appPasswords = {};
						for (var i = 0; i < success.data.appPasswords.length; ++i) {
							var a = success.data.appPasswords[i];
							vm.appPasswords[a.deviceId] = a;
						}
					}, function (failure) {
						vm.showPassword = false;
						vm.password = "";
						vm.appPasswords = {};
						errorHandling.report(failure);
					})
					.finally(function () {
						$rootScope.spinner.hide();
					});
			} else {
				if (show) {
					vm.showPassword = true;
				} else {
					vm.showPassword = false;
				}
			}
		};

		function showAppPassword(pass) {
			if (Object.keys(vm.appPasswords).length === 0) {
				$rootScope.spinner.show();
				var params = JSON.stringify({
					token: authStorage.getToken(),
					emailAddress: vm.editingCard.emailAddress
				});
				$http.post("~/api/v1/settings/domain/show-password/", params)
					.then(function (success) {
						vm.appPasswords = {};
						for (var i = 0; i < success.data.appPasswords.length; ++i) {
							var a = success.data.appPasswords[i];
							vm.appPasswords[a.deviceId] = a;
						}
						vm.appPasswordsShow[pass] = true;
					}, function (failure) {
						vm.appPasswords = {};
						errorHandling.report(failure);
					})
					.finally(function () {
						$rootScope.spinner.hide();
					});
			} else {
				vm.appPasswordsShow[pass] = true;
			}
		}

		function resetAppPassword(pass) {
			var confirm = $mdDialog.confirm({
				title: $translate.instant('CONFIRMATION_REQUIRED'),
				textContent: $translate.instant('APP_PASSWORD_RESET_CONFIRM'),
				ok: $translate.instant('RESET'),
				cancel: $translate.instant('CANCEL')
			});
			$mdDialog.show(confirm)
				.then(function (success) {
					var password = vm.appPasswords[pass];
					var params = {
						id: password.guid,
						email: vm.editingCard.emailAddress,
						token: authStorage.getToken()
					}
					$http.post('~/api/v1/settings/domain/reset-app-password', params)
						.then(function (success) {
							var keys = Object.keys(vm.appPasswords);
							for (var i = 0; i < keys.length; ++i) {
								if (vm.appPasswords[keys[i]].guid === success.data.appPassword.guid) {
									vm.appPasswords[keys[i]].password = success.data.appPassword.password;
									break;
								}
							}
						}, errorHandling.report);
				});
		}

		function changeAuthType() {
			if (vm.modUser.userData.securityFlags.authProviderId && vm.authProvider.type === 0 && vm.newPassword === "") {
				vm.needsPasswordForSMAuthType = true;
				$timeout(function () {
					$mdDialog
						.show({
							controller: "userEditPasswordAdminController",
							controllerAs: "ctrl",
							locals: { username: vm.modUser.userData.userName },
							templateUrl: "app/settings/shared/change-user-password-admin.dlg.html"
						})
						.then(function (modalSuccess) {
							if (modalSuccess.newPassword === "" || modalSuccess.confirmPassword === "") {
								errorHandling.report("INVALID_PASSWORDS");
								return;
							} else if (modalSuccess.newPassword !== modalSuccess.confirmPassword) {
								errorHandling.report("PASSWORDS_DONT_MATCH");
								return;
							}
							vm.newPassword = modalSuccess.newPassword;
						}, function () {
							vm.newPassword = "";
							vm.authProvider =
								vm.authProviders.find(p => p.id === vm.modUser.userData.securityFlags.authProviderId);
						});
				}, 500);
			}
		}

		function disableUserTwoFactor() {
			if (!vm.canChangeTwoFactor)
				return;

			var confirm = $mdDialog.confirm()
				.title($translate.instant("CONFIRMATION_REQUIRED"))
				.textContent($translate.instant('TWO_FACTOR_WANT_RESET'))
				.ok($translate.instant('RESET'))
				.cancel($translate.instant("CANCEL"));
			$mdDialog.show(confirm).then(function () {
				$http.post('~/api/v1/settings/domain/disable-two-factor/' + vm.modUser.userData.userName)
					.then(function () {
						successHandling.report("ACTION_SUCCEEDED");
						vm.twoFactorEnabled = false;
						vm.canShowAppPasswords = false;
					}, errorHandling.report);
			});
		}

		function getTranslatedThrottleOption(option) {
			var index = vm.throttleOptions.length - 1 - option;
			if (isNaN(index))
				return undefined;
			return $translate.instant(vm.throttleOptions[index].translation);
		}

		function mailForwardingChanged() {
			if (vm.modUser.userMailSettings.enableMailForwarding) {
				vm.modUser.mailboxForwardList.keepRecipients = true;
			}
		}

		function onCopyPassword() {
			var textArea = document.createElement("textarea");
			textArea.value = vm.password || "";
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand("copy");
			document.body.removeChild(textArea);
			successHandling.report("COPIED_TO_CLIPBOARD");
		}

		function onCopyTempPassword() {
			var textArea = document.createElement("textarea");
			textArea.value = vm.modUser.userData.tempPassword || "";
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand("copy");
			document.body.removeChild(textArea);
			successHandling.report("COPIED_TO_CLIPBOARD");
		}
		// #region AUTOCLEAN  --------------------------------------------------------------------------------------
		async function onAutoCleanSettingsLoad(data) {
			vm.autoCleanSettings = data.data;
			try {
				vm.autoCleanSettings.domainRules.forEach(rule => rule.translatedPath = autoCleanGetFolderNameTranslation(rule.folderName));
				vm.autoCleanSettings.systemRules.forEach(rule => rule.translatedPath = autoCleanGetFolderNameTranslation(rule.folderName));
				if (vm.autoCleanSettings.userOverrideActive && vm.autoCleanSettings.userRules) {
					vm.autoCleanFolders = await coreDataUserFolder.getUserMailFolderListByEmail(vm.userId);
					if (vm.autoCleanSettings.userRules) {
						vm.autoCleanSettings.userRules.forEach(rule => rule.translatedPath =
							autoCleanGetExistingFolderNameTranslation(rule.folderName));
					}
				} else {
					if (vm.autoCleanSettings.allowDomainOverride && vm.autoCleanSettings.domainOverrideActive) {
						vm.autoCleanInheritedRuleSource = "domain";
						vm.autoCleanInheritedRules = vm.autoCleanSettings.domainRules;
					}
					else {
						vm.autoCleanInheritedRuleSource = "system";
						vm.autoCleanInheritedRules = vm.autoCleanSettings.systemRules;
					}
				}
			} catch (e) {
				errorHandling.report(e);
				return;
			}
		};

		function autoCleanGetFolderNameTranslation(folderName) {
			switch (folderName.toLowerCase()) {
				case "deleted items": return $translate.instant("DELETED_ITEMS");
				case "inbox": return $translate.instant("INBOX");
				case "drafts": return $translate.instant("DRAFTS");
				case "junk e-mail": return $translate.instant("JUNK_EMAIL");
				case "sent items": return $translate.instant("SENT_ITEMS");
				default: return folderName;
			}
		}

		function autoCleanGetExistingFolderNameTranslation(folderName) {
			const folder = vm.autoCleanFolders.find(acf => acf.path === folderName.toLowerCase());
			return folder && folder["translatedPath"] ? folder["translatedPath"] : folderName;

		}
		async function getAutoCleanFolders() {
			if (!vm.autoCleanFolders) {
				vm.autoCleanFolders = await coreDataUserFolder.getUserMailFolderList(vm.modUser.userData.userName);
			}
			return vm.autoCleanFolders;
		}
		vm.autoCleanNewRule = async function (ev, form) {
			try {

				vm.autoCleanFolders = await getAutoCleanFolders();
			} catch (err) {
				errorHandling.report(err);
				return;
			}
			try {
				const defaultFolder = vm.autoCleanFolders.find(f => f.path === "deleted items")["name"] || "deleted Items";

				const dialogSuccess = await $mdDialog
					.show({
						locals: {
							folderName: defaultFolder,
							folders: vm.autoCleanFolders,
							processGoal: 75,
							processThreshold: 100,
							thresholdDays: 30,
							type: 0,
							enabled: true,
							newItem: true
						},
						controller: "userEditAutoCleanController",
						controllerAs: "ctrl",
						templateUrl: "app/settings/shared/edit-auto-clean.dlg.html",
						targetEvent: ev
					});
				if (dialogSuccess) {
					onNewRuleOk(dialogSuccess);
				}
			} catch (err) {
				// cancelled
			}

			function onNewRuleOk(modalSuccess) {
				modalSuccess.rule.processGoalBytes = modalSuccess.rule.processGoalMb * 1024 * 1024;
				modalSuccess.rule.processThresholdBytes = modalSuccess.rule.processThresholdMb * 1024 * 1024;

				vm.autoCleanSettings.userRules.push(modalSuccess.rule);
				form.$setDirty();
				$rootScope.$broadcast("masonry:contentsChanged");
			}
		}

		vm.autoCleanEditRule = async function (ev, rule, form) {
			try {

				vm.autoCleanFolders = await getAutoCleanFolders();
			} catch (err) {
				errorHandling.report(err);
				return;
			}
			try {
				const dialogSuccess = await $mdDialog
					.show({
						locals: {
							folderName: rule.folderName,
							folders: vm.autoCleanFolders,
							processGoal: rule.processGoalMb,
							processThreshold: rule.processThresholdMb,
							thresholdDays: rule.thresholdDays,
							type: rule.type,
							enabled: rule.enabled,
							newItem: false
						},
						controller: "userEditAutoCleanController",
						controllerAs: "ctrl",
						templateUrl: "app/settings/shared/edit-auto-clean.dlg.html",
						targetEvent: ev
					});
				if (dialogSuccess) {
					onEditRuleOk(dialogSuccess);
				}
			} catch (err) {
				// cancelled
			}

			function onEditRuleOk(modalSuccess) {
				if (modalSuccess.deleteItem) {
					autoCleanDeleteItem(rule, form);
					return;
				}
				modalSuccess.rule.id = rule.id;
				modalSuccess.rule.processGoalBytes = modalSuccess.rule.processGoalMb * 1024 * 1024;
				modalSuccess.rule.processThresholdBytes = modalSuccess.rule.processThresholdMb * 1024 * 1024;

				autoCleanDeleteItem(rule, form);
				vm.autoCleanSettings.userRules.push(modalSuccess.rule);
				form.$setDirty();
				$rootScope.$broadcast("masonry:contentsChanged");
			}
		}

		function autoCleanDeleteItem(rule, form) {
			var index = vm.autoCleanSettings.userRules.indexOf(rule);
			if (index !== -1)
				vm.autoCleanSettings.userRules.splice(index, 1);
			form.$setDirty();
			$rootScope.$broadcast("masonry:contentsChanged");
		}

		vm.autoCleanGetCleanBottomString = function (rule) {
			switch (rule.type) {
				case 0:
					var over = $filter("bytes")(rule.processThresholdBytes, 1);
					var reduce = $filter("bytes")(rule.processGoalBytes, 1);
					return $filter("translate")("AUTO_CLEAN_SUMMARY_SIZE", { over: over, reduce: reduce });
				case 1:
					return $filter("translate")("AUTO_CLEAN_SUMMARY_TIME_IN_FOLDER", { days: rule.thresholdDays });
				case 2:
					return $filter("translate")("AUTO_CLEAN_SUMMARY_MESSAGE_AGE", { days: rule.thresholdDays });
				default:
					return "";
			}
		};
		// #endregion AUTOCLEAN

		vm.onDefaultFolderChange = function () {
			vm.defaultFolderEditsPending =
				(vm.tasksDefault && !vm.tasksDefault.isPrimary) ||
				(vm.notesDefault && !vm.notesDefault.isPrimary) ||
				(vm.tasksDefault && !vm.contactsDefault.isPrimary) ||
				(vm.calendarDefault && !vm.calendarDefault.isPrimary);
		}

		vm.openRemoteContentExceptions = function (event, form) {
			$mdDialog.show({
				locals: {
					header: $translate.instant("REMOTE_CONTENT_EXCEPTIONS"),
					exceptions: angular.copy(vm.modUser.userMailSettings.remoteContentExceptions)
				},
				controller: "remoteContentExceptionsDialogController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/user-settings/account-settings/content-exceptions.dlg.html",
				targetEvent: event
			})
				.then(function (modalSuccess) {
					vm.modUser.userMailSettings.remoteContentExceptions = modalSuccess.exceptions;
					form.$setDirty();
				}, function () { });
		}

		vm.openRemoteTrackersExceptions = function (event, form) {
			$mdDialog.show({
				locals: {
					header: $translate.instant("REMOTE_TRACKERS_EXCEPTIONS"),
					exceptions: angular.copy(vm.modUser.userMailSettings.remoteTrackersExceptions)
				},
				controller: "remoteContentExceptionsDialogController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/user-settings/account-settings/content-exceptions.dlg.html",
				targetEvent: event
			})
				.then(function (modalSuccess) {
					vm.modUser.userMailSettings.remoteTrackersExceptions = modalSuccess.exceptions;
					form.$setDirty();
				}, function () { });
		}
	}

})();
