(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataUserFolder", coreDataUserFolder);

	function coreDataUserFolder($http, $translate, $q, $filter, $state, userDataService, errorHandling) {
		const vm = this;
		const priorities = ["inbox", "deleted items", "drafts", "junk e-mail", "sent items"];
		vm.isSysAdmin = userDataService.user.isSysAdmin && !$state.params.id;
		vm.init = function() {
			const defer = $q.defer();
			const loadDomains = function() {
				$http.get("~/api/v1/settings/sysadmin/internationalized-domain-names")
					.then(function(result) {
							vm.domains = angular.copy(result.data.data);
							vm.domains.unshift({ asciiName: $translate.instant("ALL") });
							vm.selectedDomain = vm.domains[0];
							defer.resolve();
						},
						defer.reject);
			};
			const onUserDataLoaded = function() {
				vm.isSysAdmin = userDataService.user.isSysAdmin;
				if (vm.isSysAdmin) {
					loadDomains();
				} else {
					vm.domainOptions = [userDataService.user.domain];
					vm.selectedDomain = userDataService.user.domain;
					defer.resolve();
				}
			};
			
			userDataService.init()
				.then(onUserDataLoaded,
					defer.reject);
			return defer.promise;
		}
		vm.queryUsers = function (searchText) {
			if (searchText.length > 0) {
				return $http
					.get(vm.isSysAdmin ? 
						`~/api/v1/settings/sysadmin/list-usernames/${vm.selectedDomain.asciiName}?usersToReturn=50&includeDomain=false&search=${searchText}` : 
						`~/api/v1/settings/domain/list-usernames/?usersToReturn=50&includeDomain=false&includeDomainAliases=false&search=${searchText}`)
					.then(function (result) { return result.data.usernames; }, errorHandling.report)
					.catch(function () { return []; });
			}

			return $q.when([]);
		}
		vm.getUserMailFolderListByEmail = function(emailAddress) {
			const userParts = emailAddress.split("@");
			if (userParts.length === 2) {
				var domainMatches = $.grep(vm.domains, (item, index) => item.asciiName.toLowerCase() == userParts[1] || (item.idnName && item.idnName.toLowerCase() == userParts[1]));
				if (domainMatches.length !== 1)
					return $q.reject("unknown domain");
				vm.selectedDomain = domainMatches[0];
				return vm.getUserMailFolderList(userParts[0]);
			} else {
				return $q.reject("invalid email address");
			}
		}
		vm.getUserMailFolderList = function(username) {
			const defer = $q.defer();
			if (!username)
				return $q.reject("invalid username");
			const userEmail = vm.isSysAdmin && vm.selectedDomain === vm.domains[0]
				? vm.targetEmail
				: `${username}@${vm.selectedDomain.asciiName}`;
			const folderListApi = vm.isSysAdmin
				? `~/api/v1/folders/sysadmin/list-email-folders/${userEmail}`
				: `~/api/v1/folders/domain/list-email-folders/${username}`;
			$http.get(folderListApi)
				.then(function(result) {
					const folderList = result.data.folderList;
					folderList.forEach(f => f.translatedPath =
						f.translatedName ? $filter("translate")(f.translatedName) : f.displayPath);
					let newFolderList = $.extend(true, [], folderList);
					for (let i = 0; i < folderList.length; ++i) {
						folderList[i].path = folderList[i].path.toLowerCase();
						
						if (folderList[i].subFolders) {
							newFolderList = loadRecursiveFolders(folderList[i], newFolderList);
						}
					}

					const index = -1;
					for (let i = 0; i < newFolderList.length; ++i) {
						newFolderList[i].path = newFolderList[i].path.toLowerCase();

						// We want the mandatory mail folders to always appear first and in a set order
						const priority = priorities.indexOf(newFolderList[i].path.toLowerCase());
						if (priority !== -1 && !newFolderList[i].isMappedFolder && !newFolderList[i].isMappedSubfolder) {
							newFolderList[i].priority = priority;
						} else {
							newFolderList[i].sortName = newFolderList[i].translatedName || newFolderList[i].name;
						}

					}
					newFolderList = $filter('orderBy')(newFolderList, ['priority', 'sortName']);
					if (index !== -1) {
						newFolderList.splice(index, 1);
					}

					if (!newFolderList || newFolderList.length === 0) {
						defer.reject($filter("translate")("MAIL_ERRORS_NO_AVAILABLE_FOLDERS"));
					} else {
						defer.resolve(newFolderList);
					}

				}, function (failure) {
					defer.reject(failure);
				});
			return defer.promise;
		}

		function loadRecursiveFolders(folder, folderList) {

			for (let i = 0; i < folder.subFolders.length; ++i) {
				const f = folder.subFolders[i];
				f.path = f.path.toLowerCase();

				let index = 0;
				for (let j = 0; j < folderList.length; ++j) {
					if (folderList[j].path === folder.path) { index = j; break; }
				}
				if (!index) { index = folderList.length - 1; };
				f.translatedPath = folder.translatedPath + "/" + f.name;
				f.name = folder.name + "/" + f.name;
				folderList.splice(index + 1, 0, f);
				if (f.subFolders) {
					loadRecursiveFolders(f, folderList);
				}
			}
			return folderList;
		};
	}
})();