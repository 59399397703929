(function () {
    'use strict';

    angular
        .module('smartermail')
        .controller('aliasSummaryController', aliasSummaryController);

    function aliasSummaryController($scope, $mdDialog, name, targets) {
        $scope.name = name;
        $scope.targets = targets;

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    };
})();